.header
  padding: 17px 50px 0 30px
  font-size: 12px
  line-height: 23px
  +clr

.header__left
  float: left
  color: $gray
  .logo
    margin-right: 35px
  > span
    display: inline-block
    vertical-align: middle

.header__right
  float: right

.header__center
  width: 300px
  margin: auto
  text-align: center
  .btn
    position: relative
    visibility: hidden
    pointer-events: none
    transform: translate3d(0,-50px,0)
    opacity: 0
    top: -6px
    vertical-align: top
    height: 23px
    color: $gray
    transition: all 0.8s ease
    &.is-visible
      visibility: visible
      opacity: 1
      transform: translate3d(0,0,0)
      pointer-events: auto
  .btn__hover
    height: 35px

.header__phone
  display: inline-block
  line-height: 1.2
  color: inherit
  text-decoration: none
  +f(hb, 12, 0.25)
  em
    color: $gray
    font-style: normal

.header__lang
  display: inline-block
  margin-left: 12px
  text-transform: uppercase
  +f(hb, 10, 0.25)
  background: none
  color: inherit
  text-decoration: none


.footer
  position: fixed
  z-index: 0
  bottom: 0
  left: 0
  right: 0
  height: 510px
  box-sizing: border-box
  +f(hr, 10, 0.05)
  background: #FFF
  color: #CCC
  overflow: hidden

.footer__map
  position: absolute
  z-index: 0
  bottom: 60px
  left: 0
  right: 0
  height: 450px
  background: #ccc

.footer__bottom
  position: absolute
  z-index: 1
  bottom: 0
  left: 0
  right: 0
  height: 60px
  padding: 15px 50px 15px 30px
  line-height: 30px
  box-sizing: border-box
  +clr

.footer__copy
  float: left
  text-transform: uppercase

.footer__link
  float: right
  > a
    +f(hb, 10, 0.3)
    color: $text
    text-decoration: none
    margin-left: 5px
    +trans(color)
    &:hover
      color: lighten($text, 20%)


.logo
  display: inline-block
  vertical-align: middle
  color: inherit
  text-decoration: none
  fill: $text
  +hide-text


#map
  width: 100%
  height: 100%
  overflow: hidden


.nav
  width: 33px
  min-height: $content-height

.nav__inner
  position: relative
  transform: rotate(90deg)
  transform-origin: bottom left
  width: 100vh
  min-width: $content-height
  height: 33px
  margin-top: -33px
  overflow: hidden
  &:before
    content: ""
    position: absolute
    bottom: 0
    left: 23px
    width: 10px
    height: 10px
    background: $text

.nav__list
  position: relative
  display: flex
  flex-direction: row
  justify-content: space-between
  flex-wrap: nowrap
  align-items: flex-end
  align-content: center
  height: 33px
  width: 100%
  width: 100vh
  min-width: $content-height
  padding: 6px 30px 0 50px
  box-sizing: border-box
  > li
    display: flex
    width: auto
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    height: 6px
    width: 100%
    background: $gray
  .scrollbar-width-0 &:before
    height: 9px

.nav__back
  width: 100%
  .nav__list
    color: $gray
    .nav__link
      &:hover
        color: $text

.nav__front
  position: absolute
  top: 0
  left: 0
  bottom: 0
  width: 100%
  overflow: hidden
  pointer-events: none
  // transition: width 0.1s linear
  .nav__list
    color: $text
    &:before
      background: $text
  .nav__link
    &:hover
      color: $text

.nav__link
  display: inline-block
  padding-top: 20px
  color: inherit
  text-decoration: none
  background: none
  +f(hb, 10, 0.25)
  text-transform: uppercase
  line-height: 1
  +trans(color)


//---------------------------------------------------------------------------
//            ##### begin common blocks #####
//---------------------------------------------------------------------------

.blue-circle
  width: 510px
  height: 510px
  border-radius: 50%
  background-color: #75BDC4
  background-image: linear-gradient(to top, #CDF0E9 27%, #2B92A5 100%)


.price
  display: block
  +f(hb, 72, 0.05)

.price__small
  display: inline-block
  font-size: 24px
  letter-spacing: 0
  sup
    vertical-align: super
    font-size: 12px


.details-btn
  display: inline-block
  background: none
  +f(hb, 10, 0.5)
  text-transform: uppercase
  text-decoration: none
  color: inherit
  line-height: 40px
  > span
    display: inline-block
    vertical-align: middle
  .icon-arrow
    vertical-align: middle
    transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275)
  &:hover
    .icon-arrow
      transform: translateX(20px)


.section
  position: relative

.deco
  position: relative
  display: inline-block
  +f(hr, 12, 0.25)

.deco__inner
  position: relative

.deco_polygon
  .deco__inner
    width: 41px
    height: 46px
    line-height: 46px
    font-size: 18px
    letter-spacing: 0.05em
    text-align: center
  .icon-polygon
    position: absolute
    top: 0
    left: 0

.deco_circle
  .deco__inner
    width: 33px
    height: 33px
    border: 1px solid
    text-align: center
    line-height: 34px
    font-size: 18px
    letter-spacing: 0
    border-radius: 50%


.heading
  display: block
  +f(hb, 16, 0.3)
  text-transform: uppercase
  color: $text

.heading__small-text
  margin-bottom: 5px
  display: block
  font-size: 12px
  letter-spacing: 0.15em
  color: $gray

.heading_center
  text-align: center

.heading_large
  font-size: 18px
  .heading__small-text
    font-size: 12px

.heading_small
  font-size: 12px

.heading_red
  font-size: 10px
  color: #f00
  margin-bottom: 17px

.heading_border
  position: relative
  padding-bottom: 17px
  &:after
    content: ""
    position: absolute
    bottom: 0
    left: 0
    width: 100px
    height: 3px
    background: $text
  .heading__small-text
    margin-bottom: 8px
    color: inherit


.text
  +f(bsr, 16)
  line-height: 1.375
  p
    margin: 12px 0
    &:first-child
      margin-top: 0
    &:last-child
      margin-top: 0

.amount
  +f(hb, 18)
  line-height: 1

.amount__sign
  font-size: 30px
  display: inline-block
  vertical-align: middle
  margin-right: 10px

.amount__value
  font-size: 36px
  display: inline-block
  vertical-align: middle
  font-weight: normal

.amount__text
  position: relative
  display: inline-block
  vertical-align: bottom
  bottom: 3px
  font-size: 18px
  margin-left: 10px

.amount_md
  // .amount__sign
  .amount__value
    font-size: 48px
  .amount__text
    bottom: 5px

.amount_lrg
  .amount__sign
    font-size: 48px
  .amount__value
    font-size: 60px
  .amount__text
    font-size: 24px
    bottom: 6px


.btn
  display: inline-block
  position: relative
  box-sizing: border-box
  -webkit-appearance: none
  text-align: left
  text-decoration: none
  color: $text
  background: none
  white-space: nowrap
  &:hover,
  &.is-active
    .btn__hover
      width: 100%

.btn__hover
  position: absolute
  z-index: 1
  top: 0
  left: 0
  bottom: 0
  width: 0
  background: $text
  color: #FFF
  +trans(width)
  overflow: hidden

.btn__hover-in
  box-sizing: border-box
  height: 100%
  position: relative
  z-index: 1

.btn__front
  position: relative
  box-sizing: border-box
  z-index: 0

.btn_link
  width: 350px
  height: 44px
  border: 3px solid $text
  color: $text
  line-height: 38px
  text-align: center
  +f(hb, 12, 0.20)
  text-transform: uppercase
  .btn__hover
    &:before
      content: attr(data-hover)
      display: block
      width: 343px
      height: 38px
      color: #FFF

.btn_submit
  height: 72px
  line-height: 72px
  width: 100%
  white-space: nowrap
  +f(hb, 10, 0.3)
  color: $text-light
  text-transform: uppercase
  .icon-arrow
    position: relative
    top: 1px
    margin-left: 10px
    fill: currentColor
    transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275)
  .btn__hover-in,
  .btn__front
    padding: 0 0 0 70px
  &:hover
    .icon-arrow
      transform: translate(20px, 0)


.btn_transparent
  +f(hb, 10, 0.3)
  color: $text-light
  text-transform: uppercase
  line-height: 35px
  .btn__hover
    background: none
    color: $text
  .btn__hover-in
    color: $text
    &:before
      content: ""
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 3px
      background: $text



.form
  position: relative

.form.is-success
  .form__message
    opacity: 1
    visibility: visible

.form__message
  position: absolute
  z-index: 5
  top: 3px
  left: 3px
  right: 3px
  bottom: 3px
  opacity: 0
  visibility: hidden
  transition: 0.5s ease
  background: #FFF
  transition-property: opacity, visibility
  overflow: hidden
  +vertical
  +f(hr,16,0.1)
  strong
    display: block
    margin-bottom: 10px
    +f(hr,20,0.3)
    text-transform: uppercase
  .contacts-table &
    top: 1px
    left: 0

.form__message-inner
  display: inline-block
  vertical-align: middle

.form__contact
  height: 184px
  padding: 35px 0 0 40px
  +f(bsr, 14)
  color: $text-light
  box-sizing: border-box
  > p
    margin: 10px 0
    color: $text
    text-transform: uppercase
    +f(hr, 14, 0.1)


.input
  display: block
  width: 100%
  height: 52px
  padding: 15px 15px 15px 70px
  box-sizing: border-box
  +f(bsr, 16, 0.03)
  color: $text
  +trans(color)
  border: none
  box-shadow: none
  -webkit-appearance: none
  background: none
  overflow: hidden
  +placeholder
    color: $text-light
    line-height: normal
    transition: all 0.5s ease
  &:hover
    +placeholder
      color: $text
  &:focus
    +placeholder
      color: $text
      transform: translate(50px, 0)
      opacity: 0

textarea.input
  height: 214px
  resize: none
  margin: 0


.sold-badge
  display: inline-block
  white-space: nowrap
  color: #ED1D24
  +f(hb, 12, 0.5)
  text-transform: uppercase
  border: #ED1D24
  line-height: 34px
  border: 3px solid
  padding: 0 20px
  min-width: 200px
  text-align: center
  box-sizing: border-box
  transform: rotate(-12deg)


.btn-back
  display: inline-block
  background: none
  +f(hb, 10, 0.5)
  text-transform: uppercase
  text-decoration: none
  color: inherit
  line-height: 40px
  > span
    display: inline-block
    vertical-align: middle
  .icon-arrow
    vertical-align: middle
    margin-right: 20px
    transform: translate3d(0,0,0) rotate(180deg)
    transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275)
  &:hover
    .icon-arrow
      transform: translate3d(-20px,0,0) rotate(180deg)



#morph
  position: absolute
  z-index: 0
  top: 15px
  left: 50%
  margin-left: -684px
  width: 1368px
  height: 680px
  pointer-events: none
  opacity: 0
  visibility: hidden

#morph2
  position: absolute
  z-index: 0
  top: 50%
  left: 50%
  width: 1368px
  height: 680px
  margin: -340px 0 0 -684px
  pointer-events: none
  opacity: 0


// [data-filter]
//   animation: detail 0.5s ease 1


//---------------------------------------------------------------------------
//            ^^^^^  end common blocks  ^^^^^
//---------------------------------------------------------------------------




//---------------------------------------------------------------------------
//            ##### begin main slider #####
//---------------------------------------------------------------------------

.main-slider
  position: relative
  z-index: 1
  height: 656px
  max-width: 1368px
  margin: auto
  overflow: hidden
  .slick-dots
    position: absolute
    z-index: 3
    top: 175px
    left: 50%
    width: 40px
    margin-left: -20px
    text-align: center
    animation: slide-left 0.5s ease 1
    li
      padding: 0
      margin: 0
    button
      display: block
      margin-bottom: 5px
      width: 40px
      height: 40px
      text-align: center
      color: $text
      +f(hb, 12, 0.25)
      line-height: 2
      background: none
      opacity: 0.3
      +trans(opacity)
      &:hover,
        opacity: 1
    .slick-active
      button
        opacity: 1


.main-slider__slides
  transform-origin: top center
  position: relative
  z-index: 2

.main-slider__morph
  position: absolute
  top: 100px
  left: 50%
  width: 510px
  height: 510px
  margin-left: -255px

.main-slider__overlay
  display: none
  position: absolute
  z-index: 5
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: #FFF

.main-slider__paginator
  position: absolute
  z-index: 3
  top: 175px
  left: 50%
  width: 40px
  margin-left: -20px
  text-align: center
  button
    display: block
    margin-bottom: 5px
    width: 40px
    height: 40px
    text-align: center
    color: $text
    +f(hb, 12, 0.25)
    line-height: 2
    background: none
    opacity: 0.3
    +trans(opacity)
    &:hover,
    &.is-active
      opacity: 1


.slide
  position: relative
  z-index: 1
  width: 100%
  height: 656px
  &.slick-active
    .slide__img
      animation: img-slide-in 0.8s cubic-bezier(0, 0.99, 0, 0.99)
    .slide__content
      animation: content-slide-in 0.8s ease

.slide__img
  position: absolute
  z-index: 0
  bottom: 0
  left: 0
  max-width: 100%
  transform-origin: bottom center
  .slick-slide &
    animation: img-slide-out 0.8s ease-out

.slide__content
  position: absolute
  z-index: 1
  top: 180px
  left: percentage(740/1368)
  width: 500px
  font-size: 36px
  line-height: 1.2
  letter-spacing: 0.05em
  .slick-slide &
    animation: content-slide-out 0.8s ease

//---------------------------------------------------------------------------
//            ^^^^^  end main slider  ^^^^^
//---------------------------------------------------------------------------



//---------------------------------------------------------------------------
//            ##### begin catalog #####
//---------------------------------------------------------------------------

.catalog
  height: 680px
  // height: 100vh
  // max-height: 900px
  // min-height: 680px
  // position: relative
  // z-index: 0
  #morph
    z-index: 0
    &.is-visible
      z-index: 2
      & ~ .deco
        opacity: 1
        visibility: visible
        transform: none
        transition-delay: 1s

.catalog__modal
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  height: 680px
  min-width: $content-width
  transition: background 0.5s ease
  &.is-opened
    background: #FFF
    .catalog__modal-container
      +scroll
      +wos

.catalog__modal-container
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

.catalog__inner
  position: relative
  width: 100%
  margin: auto

.catalog__toparea
  position: relative
  height: 680px
  z-index: auto
  .deco
    position: absolute
    z-index: 4
    top: 145px
    right: 125px
    opacity: 0
    visibility: hidden
    transform: translateY(30px)
    transition: all 0.2s linear

.catalog__content
  display: none
  padding-bottom: 70px
  position: relative
  z-index: 1

.catalog-category
  position: absolute
  z-index: 3
  top: 609px
  left: 50%
  height: 44px
  width: 510px
  margin-left: -255px
  box-sizing: border-box
  font-size: 0
  &:before
    content: ""
    position: absolute
    z-index: 0
    top: 0
    left: 0
    right: 0
    width: 510px
    height: 100%
    margin: auto
    background: #FFF
    box-shadow: 0 0 0 3px $text, 0 0 0 13px #FFF
    transition: opacity 0.2s ease, visibility 0.2s ease
    visibility: visible
    opacity: 1
  &.is-animate
    &:before
      opacity: 0
      visibility: hidden
      box-shadow: 0 0 0 13px #FFF
    .catalog-category__item
      width: 33.3333%
      &:before
        opacity: 0
        visibility: hidden
    .catalog-btn
      color: $gray
      letter-spacing: 0.5em
      width: auto
      transition-duration: 0.3s
    .catalog-btn__hover
      background: none
      color: $text
      box-shadow: none
    .catalog-btn__hover-in
      width: auto
    .catalog-btn__hover-text
      &:before
        content: ""
        position: absolute
        top: 100%
        left: 0
        right: em(4,12)
        height: 3px
        background: $text


.catalog-category__item
  position: relative
  z-index: 1
  display: inline-block
  vertical-align: middle
  width: 170px
  height: 100%
  text-align: center
  overflow: hidden
  pointer-events: none
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 1px
    background: $text
    transition: all 0.3s linear
    opacity: 1
    visibility: visible
  &:first-child
    &:before
      display: none
  &.clone
    display: none
    &.slick-slide
      display: block

  // &.is-active, &.slick-center
    // .catalog-btn
      // transform: scale(1.3)
  &.slick-center
    .catalog-btn
      font-size: 18px
    .catalog-btn__hover
      width: 100%

  // &.slick-slide
  //   .catalog-btn__hover-text,
  //   .catalog-btn__front,
  //   .catalog-btm__hover
  //     transition-duration: 0.3s
  // &.slick-center
  //   .catalog-btn__hover-text,
  //   .catalog-btn__front,
  //   .catalog-btm__hover
      // transition-delay: 0.3s

.catalog-btn
  display: inline-block
  position: relative
  z-index: 1
  height: 44px
  width: 170px
  line-height: 44px
  +f(hb, 10, 0.20)
  color: $text
  text-transform: uppercase
  text-decoration: none
  text-align: center
  overflow: hidden
  white-space: nowrap
  pointer-events: auto
  transform: translate3d(0,0,0)
  transition: all 0.15s linear
  &:hover
    .catalog-btn__hover
      width: 100%

.catalog-btn__hover
  position: absolute
  z-index: 1
  top: 0
  left: 0
  width: 0
  height: 100%
  overflow: hidden
  transition: width 0.2s linear
  background: $text
  color: #FFF
  box-shadow: -2px 0 0 0 $text

.catalog-btn__hover-in
  position: absolute
  z-index: -1
  top: 0
  left: 0
  bottom: 0
  width: 170px
  box-sizing: border-box
  overflow: hidden

.catalog-btn__hover-text
  display: inline-block
  position: relative
  line-height: 1.7
  // transition: all 0.5s linear


//---------------------------------------------------------------------------
//            ^^^^^  end catalog  ^^^^^
//---------------------------------------------------------------------------



//---------------------------------------------------------------------------
//            ##### begin section facts #####
//---------------------------------------------------------------------------

.facts
  position: relative
  padding: 360px 0 265px
  box-sizing: border-box
  > .heading
    text-align: center
  .deco
    position: absolute
    z-index: 2
  .deco-1
    top: 93px
    right: 50%
    margin-right: -330px
  .deco-2
    top: 1070px
    left: 100px
  .deco-3
    top: 1850px
    right: 240px
  .deco-4
    bottom: 340px
    right: 165px

.facts__heading
  text-align: center
  position: relative
  z-index: 3
  .heading
    display: inline-block
    text-align: left
    line-height: 1.875

.facts__text
  max-width: 780px
  margin: 200px auto 450px
  +clr

.facts__column
  position: relative
  float: left
  width: 50%
  box-sizing: border-box
  &:first-child
    float: left
    padding-right: 30px
  &:last-child
    float: right
    padding-left: 30px
    transition-delay: 0.1s
  +slide-fade(facts__text)

.facts-group
  height: 270px
  position: relative
  margin: 165px 0 65px
  &.is-animate
    .fact-2
      transform: none
    .curve
      stroke-dashoffset: 0

.facts-group__inner
  position: relative
  z-index: 1
  max-width: 1000px
  height: 100%
  margin: auto
  // box-shadow: 0 0 0 1px #ccc


// .box-wrapper
//   margin: 180px 0 390px
//   position: relative
//   height: 520px

.box
  position: relative
  height: 520px
  margin: 180px 0 145px
  box-sizing: border-box
  +vertical
  white-space: nowrap
  // pointer-events: none

.box__bg
  position: absolute
  z-index: 0
  top: 50%
  left: 0
  right: 0
  height: 528px
  margin-top: -264px
  white-space: normal
  pointer-events: none
  // background-image: url('../img/svg/man.svg')
  transition: all 0.5s ease
  opacity: 1
  // .box.is-animate &
  //   transform: scale(0.5,0.5)
  //   opacity: 0
  .icon-man
    opacity: 0
    background: url('../img/svg/man.svg') no-repeat center center
    display: inline-block
    vertical-align: top
    width: 22px
    height: 44px
    transform: translate3d(0,-30px,0)

.box__bg-row
  height: 44px
  background: url('../img/svg/man.svg') repeat-x center center
  // transform: translate3d(0,30px,0)
  // opacity: 0

.box__inner
  display: inline-block
  vertical-align: middle
  position: relative
  z-index: 1
  font-size: 16px
  width: 720px
  height: 190px
  white-space: normal

.box__table
  display: inline-block
  width: 720px
  height: 190px
  position: relative
  pointer-events: none
  // transition: top 0.2s linear
  .table
    position: absolute
    top: 0
    left: 0


.curve
  position: absolute
  z-index: 0
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  stroke-dasharray: 2621px
  stroke-dashoffset: 1600px
  > svg
    display: block
    transition: stroke-dashoffset 3s ease

.skyline
  position: relative
  // stroke-dasharray: 4000px
  // stroke-dashoffset: -4000px
  height: 272px
  > svg
    display: block
    position: absolute
    top: 0
    left: 50%
    transform: translate(-50%, 0)
    transition: stroke-dashoffset 2s ease
  &.is-animate
    stroke-dashoffset: 0


.fact
  position: absolute
  width: 312px
  padding: 0 0 0 40px
  box-sizing: border-box
  +f(bsr, 16)

.fact-1
  top: 90px
  left: 0

.fact-2
  top: 0
  right: 120px
  transform: translate3d(-230px, 0, 0)
  transition: transform 2s ease

.fact__number
  display: block
  margin: 14px 0 14px -40px
  padding: 10px 10px 10px 40px
  border: 1px solid
  box-sizing: border-box
  +f(hb, 30)
  color: $text
  background: #FFF


.timeline
  position: absolute
  z-index: 3
  top: 450px
  left: 50%
  height: 1px
  width: 1px
  &.is-animate
    .timeline__inner
      transform: translate3d(-470px,0,0)
      width: 3100px
      transition: width 4s ease, transform 1.5s ease
    .timeline__year
      opacity: 1
      transform: translate3d(0,0,0)
      &:nth-child(2)
        transition-delay: 0.1s
      &:nth-child(3)
        transition-delay: 0.2s
      &:nth-child(4)
        transition-delay: 0.3s
      &:nth-child(5)
        transition-delay: 0.4s
      &:nth-child(6)
        transition-delay: 0.5s
      &:nth-child(7)
        transition-delay: 0.6s
      &:nth-child(8)
        transition-delay: 0.7s
      &:nth-child(9)
        transition-delay: 0.8s
      &:nth-child(10)
        transition-delay: 0.9s
      &:nth-child(11)
        transition-delay: 1s
      &:nth-child(12)
        transition-delay: 1.1s
      &:nth-child(13)
        transition-delay: 1.2s
      &:nth-child(14)
        transition-delay: 1.3s
      &:nth-child(15)
        transition-delay: 1.4s
  &.state-2
    .timeline__inner
      transform: translate3d(-1800px,554px,0)
    .timeline__year
      &:nth-child(12)
        transform: translate3d(315px,0,0)
        transition-delay: 0s
        transition-duration: 1.5s
      &:nth-child(13)
        transform: translate3d(738px,0,0)
        transition-delay: 0s
        transition-duration: 1.5s

.timeline__inner
  position: absolute
  top: 0
  left: 0
  height: 1px
  width: 1px
  background: $text
  transition: width 2s ease, transform 1.5s ease

.timeline__year
  position: absolute
  bottom: 0
  width: 35px
  height: 30px
  +f(bsr, 12, 0.15)
  transform: translate3d(0,-100%,0)
  opacity: 0
  transition: all 0.3s ease
  &:before
    content: ""
    position: absolute
    bottom: -4.5px
    left: 0
    width: 5px
    height: 10px
    background: $text
  &:first-child
    transform: none
    opacity: 1
    &:before
      width: 10px
      height: 10px
      border-radius: 50%
  // $delta: 15
  // @for $i from 1 through 30
  //   $delta: ceil($delta * 1.344)
  //   &:nth-child(#{$i + 1})
  //     left: (45 * $i + $delta) + px
  &:nth-child(2)
    left: 48px
    transition-delay: 1.4s
  &:nth-child(3)
    left: 105px
    transition-delay: 1.3s
  &:nth-child(4)
    left: 168px
    transition-delay: 1.2s
  &:nth-child(5)
    left: 240px
    transition-delay: 1.1s
  &:nth-child(6)
    left: 320px
    transition-delay: 1s
  &:nth-child(7)
    left: 410px
    transition-delay: 0.9s
  &:nth-child(8)
    left: 505px
    transition-delay: 0.8s
  &:nth-child(9)
    left: 610px
    transition-delay: 0.7s
  &:nth-child(10)
    left: 720px
    transition-delay: 0.6s
  &:nth-child(11)
    left: 850px
    transition-delay: 0.5s
  &:nth-child(12)
    left: 985px
    transition-delay: 0.4s
  &:nth-child(13)
    left: 1130px
    transition-delay: 0.3s
  &:nth-child(14)
    left: 1300px
    transition-delay: 0.2s
  &:nth-child(15)
    left: 1500px
    transition-delay: 0.1s


.table
  position: relative
  background: #FFF
  > table
    width: 100%
    table-layout: fixed
    position: relative
    z-index: 1
    text-align: left
  td, th
    box-sizing: border-box

.js-table
  transition: opacity 0.3s linear 1s, visibility 0.3s linear 1s
  opacity: 0
  pointer-events: none
  visibility: hidden
  &.is-animate, .object.is-fixed &
    transition-delay: 0s
    opacity: 1
    visibility: visible
    pointer-events: auto
    .table__border
      +svg-border-animate

.table__border
  position: absolute
  z-index: 0
  top: 0
  left: 0
  right: 0
  bottom: 0
  pointer-events: none
  svg
    display: block
    transform: translate(0,0)
  .outer-border
    transition: stroke-dashoffset 1s ease 0.3s
  .inner-border
    transition: stroke-dashoffset 1s ease
  .men-table &
    stroke-dasharray: 910px
    stroke-dashoffset: 910px
  .partners-table &
    stroke-dasharray: 910px
    stroke-dashoffset: 910px
  .tabs__head &
    stroke-dasharray: 1195px
    stroke-dashoffset: 1195px
  .tabs__content &
    stroke-dasharray: 1464px
    stroke-dashoffset: 1464px
  .contacts-table &
    stroke-dasharray: 1568px
    stroke-dashoffset: 1568px
  .info &
    stroke-dasharray: 825px
    stroke-dashoffset: 825px
  &.is-animate
    +svg-border-animate


.men-table
  z-index: 1
  width: 720px

.men-table__cell
  text-align: left
  padding: 0 10px 0 45px
  vertical-align: middle
  box-sizing: border-box
  &:first-child
    width: 196px
  &:nth-child(2)
    width: 235px
  &:last-child
    width: 290px

.men-table__row1
  .men-table__cell
    height: 90px

.men-table__row2
  .men-table__cell
    height: 100px

// .men-table,
// .partners-table
//   opacity: 0
//   transition: opacity 0.3s linear 0.7s
//   &.is-animate
//     transition-delay: 0s
//     opacity: 1
//     .table__border
//       stroke-dashoffset: 0


.partners-table
  z-index: 0
  width: 720px
  margin: 0 auto 50px

.partners-table__row1
  .partners-table__cell
    height: 124px
    &:first-child
      vertical-align: top
      padding: 40px 0 0 50px

.partners-table__row2
  .partners-table__cell
    height: 66px

.partners-table__cell
  vertical-align: middle
  padding-left: 70px
  box-sizing: border-box
  &:first-child
    width: 202px


//---------------------------------------------------------------------------
//            ^^^^^  end section facts  ^^^^^
//---------------------------------------------------------------------------




//---------------------------------------------------------------------------
//            ##### begin head #####
//---------------------------------------------------------------------------

.head
  position: relative
  margin-bottom: 90px
  height: 423px
  &.last-schild
    margin-bottom: 0
  .deco
    position: absolute
    z-index: 3
    pointer-events: none
  .deco-1
    top: 230px
    right: 100px
  .deco-2
    top: -190px
    left: 150px

.head__inner
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 100%
  overflow: hidden

.head__bg
  position: absolute
  z-index: 0
  bottom: 0
  left: 0
  right: 0
  height: 423px
  background-image: linear-gradient(to top, #CDF0E9 0%, #42BAAD 100%)

.head__img
  position: absolute
  z-index: 1
  bottom: 0
  left: 120px
  img
    display: block

.head__text
  position: absolute
  z-index: 2
  // bottom: 73px
  bottom: 146px
  left: 70px
  // +f(hb, 40, 0.8)
  +f(hb, 72, 0.4)
  line-height: 1.75
  text-transform: uppercase
  pointer-events: none

.head_advantages
  .head__inner
    height: 541px
  .head__img
    left: 50%
    margin-left: -560px

.head_capabilities
  margin-bottom: 0
  .deco-1
    top: 180px
    right: 458px
  .head__text
    bottom: 73px

.head_partners
  .deco-1
    top: -90px
    right: 345px
  .head__bg
    background-image: linear-gradient(to top, #EDE4F6 0%, #4297BA 100%)
  .head__img
    left: auto
    right: 0

.head_contacts
  .head__inner
    height: 500px
  .deco-1
    top: 54px
    right: 85px
  .deco-2
    top: -120px
    left: 75px
  .head__bg
    background-image: linear-gradient(to top, #FFDCC5 0%, #25A1B0 100%)
  .head__img
    left: 50%
    margin-left: -560px

//---------------------------------------------------------------------------
//            ^^^^^  end head  ^^^^^
//---------------------------------------------------------------------------



//---------------------------------------------------------------------------
//            ##### begin section advantages #####
//---------------------------------------------------------------------------

// .advantages

.advantages__inner
  max-width: 900px
  margin: auto
  padding-left: 150px
  box-sizing: border-box
  +clr
  +f(bsr, 14)
  color: $text-light
  line-height: 1.57

.advantages__column
  float: left
  width: 33.3333%
  box-sizing: border-box
  padding-left: 50px
  .heading
    margin-bottom: 25px

//---------------------------------------------------------------------------
//            ^^^^^  end section advantages  ^^^^^
//---------------------------------------------------------------------------




//---------------------------------------------------------------------------
//            ##### begin section capabilities #####
//---------------------------------------------------------------------------
.capabilities__container
  position: relative
  height: 715px
  margin-top: -190px
  width: 100%

.capabilities-modal
  position: absolute
  top: 0
  left: 0
  width: 100%
  transition: background 0.5s ease
  &.is-opened
    background: #FFF
    .capabilities-modal__inner
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      +scroll
      +wos

.capabilities-modal__content
  display: none
  padding-bottom: 70px

.capabilities-modal__head
  position: relative
  max-width: 1368px
  height: 680px
  margin: auto

.category
  position: absolute
  z-index: 1
  top: 50%
  left: 50%
  right: 0
  width: 1500px
  margin: auto
  transform: translate(-50%, -50%)
  +clr
  &.is-standby
    .slick-center .btn__bg
      transition-property: all
  &.is-animate
    .slick-center .btn__bg
      opacity: 0
      transition: none
  &.is-active
    .btn_category
      font-size: 10px
      color: $gray
      transform: translate3d(0,0,0) !important
      &:hover
        .btn__bg
          opacity: 0
    .btn__bg
      transform: translate3d(-50px,0,0)
      opacity: 0
      visibility: hidden
    .slick-center
      .btn
        font-size: 18px
        color: $text
      .btn__hover
        width: 100%
      .btn__bg
        transform: translate3d(0,0,0)
        transition: none
    .slick-center:not(.slick-cloned) ~ .slick-slide
      .btn__bg
        transform: translate3d(50px,0,0)
    .slick-slide
      opacity: 1

.category__item
  float: left
  width: 33.333%
  line-height: 400px
  text-align: center
  pointer-events: none
  transition: opacity 0.3s ease
  +rmin(1400)
    &.slick-slide
      opacity: 0
    &.slick-active
      opacity: 1

.btn_category
  line-height: 2
  +f(hb, 18, 0.5)
  text-transform: uppercase
  pointer-events: auto
  transition: all 0.5s ease
  .btn__hover
    background: none
    color: $text
  .btn__hover-in
    &:before
      content: ""
      position: absolute
      bottom: 0
      left: 0
      right: em(6,18)
      height: 3px
      background: $text
  .btn__bg
    // display: none
    position: absolute
    z-index: -1
    top: 50%
    left: 50%
    width: 320px
    height: 320px
    margin: -159px 0 0 -160px
    background: no-repeat center center
    opacity: 0.2
    transition: all 0.5s ease-out
    .slick-center &
      transition-property: transform
  .btn__bg1
    background-image: img-url('category1.png')
  .btn__bg2
    background-image: img-url('category2.png')
  .btn__bg3
    background-image: img-url('category3.png')
  &:hover, &.is-hover
    .btn__bg
      opacity: 1
  .slick-slide &
    transform: translate3d(100px,0,0)
  .slick-center:not(.slick-cloned) ~ .slick-slide &
    transform: translate3d(-100px,0,0)
  .slick-center.slick-slide &
    transform: translate3d(0,0,0)

//---------------------------------------------------------------------------
//            ^^^^^  end section capabilities  ^^^^^
//---------------------------------------------------------------------------



//---------------------------------------------------------------------------
//            ##### begin section partners #####
//---------------------------------------------------------------------------

.partners
  padding-bottom: 235px

.partners__inner
  position: relative
  max-width: 1080px
  padding: 0 70px
  margin: auto
  box-sizing: border-box
  +f(bsr, 14)
  line-height: 1.44
  color: $text-light
  .deco
    position: absolute
    top: 40px
    right: 140px

.text-block
  margin-bottom: 70px
  font-size: 16px
  color: $text-light
  .heading
    margin-bottom: 25px


.tabs
  margin: 35px -70px 0

.tabs__head
  position: relative
  z-index: 1
  pointer-events: none
  opacity: 0
  visibility: hidden
  transition: opacity 0.3s linear 1s, visibility 0.3s linear 1s
  +clr
  .tabs.is-animate &
    pointer-events: auto
    visibility: visible
    opacity: 1
    transition-delay: 0s
    .table__border
      +svg-border-animate

.tabs__content
  display: none
  position: relative
  z-index: 0
  top: -3px

.tabs__footer
  margin-top: 35px
  transform: translate3d(0,0,0)
  +clr

.tabs__footer-cell
  float: left
  width: 33.333%
  box-sizing: border-box
  padding: 0 0 0 70px
  +slide-fade(tabs)
  &:nth-child(2)
    transition-delay: 0.1s
  &:nth-child(3)
    transition-delay: 0.2s

.btn_tab
  float: left
  width: 360px
  height: 120px
  .btn__hover-in
    width: 360px
  .heading
    color: inherit
  .btn__front,
  .btn__hover-in
    padding: 22px 0 0 70px

//---------------------------------------------------------------------------
//            ^^^^^  end section partners  ^^^^^
//---------------------------------------------------------------------------



//---------------------------------------------------------------------------
//            ##### begin section contacts #####
//---------------------------------------------------------------------------

.contacts
  padding-bottom: 80px

.contacts__inner
  width: 1080px
  margin: auto


.contacts-table
  .form
    margin-left: -70px

.contacts-table__row1
  .contacts-table__cell
    height: 104px
.contacts-table__row2
  .contacts-table__cell
    height: 286px

.contacts-table__cell
  box-sizing: border-box
  padding-left: 70px
  vertical-align: middle
  &:first-child
    width: 454px


.contact-item
  +f(hr, 18, 0.1)
  line-height: 1.667
  text-transform: uppercase
  > a
    text-decoration: none
    color: inherit

.contact-item__label
  display: block
  +f(hb, 10, 0.2)
  line-height: 2.2
  color: $text-light
  text-transform: uppercase

.contact-item__phone
  font-size: 24px


//---------------------------------------------------------------------------
//            ^^^^^  end section contacts  ^^^^^
//---------------------------------------------------------------------------



//---------------------------------------------------------------------------
//            ##### begin catalog-content #####
//---------------------------------------------------------------------------

.filter
  width: 790px
  box-sizing: border-box
  margin: 0 auto 70px
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  position: relative
  > .deco
    position: absolute
    top: -100px
    left: -140px
    right: auto
    bottom: auto
  + .object-wrapper
    margin-top: 70px

.filter__item
  display: inline-block
  vertical-align: top
  position: relative
  cursor: pointer
  > input
    position: absolute
    opacity: 0
    top: 0
    left: 0
  input:checked ~ .btn .btn__hover
    width: 100%
  .btn
    user-select: none


.object-wrapper
  width: 790px
  height: 450px
  position: relative
  margin: 130px auto
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0
  &.is-animate
    .object-label
      opacity: 0
      visibility: hidden
  &.is-slider
    width: 100%
    height: auto
    transition: height 0.5s linear
    .object-label
      opacity: 0
      visibility: hidden
    .object
      height: auto
    .object__inner
      position: relative
    .object__detail
      padding-bottom: 0
      animation: none


.object-label
  display: block
  position: absolute
  z-index: 1
  top: -55px
  left: -65px
  +f(hb, 24, 1)
  line-height: 1.333
  text-transform: uppercase
  transition: all 0.2s linear
  opacity: 1
  visibility: visible
  html[lang="en"] &
    top: -35px


.object
  position: relative
  width: 790px
  height: 450px
  background: #FFF
  .info
    position: absolute
    z-index: 5
    left: 50%
    bottom: -22px
    margin-left: -225px
    .table__border
      stroke-dashoffset: 0

  .slick-dots
    position: absolute
    top: 100%
    margin-top: 10px
    left: 20px
    right: 50%
    padding-right: 235px
    font-size: 0
    animation: slide-left 0.5s ease 1
    li
      display: inline-block
      vertical-align: middle
      button
        width: 40px
        height: 40px
        background: none
        +f(hb, 12, 0.2)
        text-transform: uppercase
        color: $gray
        +trans(color)
      &.slick-active, &:hover
        button
          color: $text

  &.is-opened,
  &.is-slider
    .object__buttons
      // display: block
      visibility: visible
      opacity: 1
    .btn_object
      cursor: default
      pointer-events: none
      .btn__hover
        transition: all 0.2s linear
        opacity: 0
        visibility: hidden
    .object__slide
      transition: none
      transform: none
  &.is-opened
    .object__inner
      +scroll
    .object__detail
      display: block


.object__inner
  position: absolute
  z-index: 0
  top: 0
  left: 0
  right: 0
  bottom: 0

.object__pic
  position: relative
  z-index: 0
  width: 100%
  height: 100%
  background: no-repeat center center
  background-size: cover

.object__buttons
  // display: none
  visibility: hidden
  opacity: 0
  position: absolute
  z-index: 1
  top: 0
  left: 0
  right: 0
  bottom: 0
  transition: 0.3s ease
  transition-property: visibility, opacity
  .popup.is-opened &
    opacity: 1
    visibility: visible

.object__detail
  display: none
  width: 790px
  margin: 160px auto 0
  box-sizing: border-box
  padding: 0 0 70px 210px
  +f(bsr, 18)
  line-height: 1.555
  animation: detail 0.5s ease
  .btn_link
    margin-top: 40px

.object__close
  position: absolute
  z-index: 3
  top: 0
  left: 50%
  width: 500px
  height: 170px
  box-sizing: border-box
  background: none
  margin-left: -250px
  +f(hb, 10, 0.2)
  text-transform: uppercase
  color: #FFF
  // opacity: 0
  // transition: opacity 0.4s linear
  text-shadow: 0 1px 2px rgba(0,0,0,0.35)
  // animation: close-btn 0.4s ease 1
  > span
    display: inline-block
    // transform: translate3d(0,20px,0)
    // transition: transform 0.4s ease
  .icon-arrow-sm
    position: absolute
    fill: #FFF
    top: 50%
    left: 50%
    margin: -50px 0 0 -4px
    transition: transform 0.6s ease
  &:hover
    // opacity: 1
    // > span
      // transform: translate3d(0,0,0)
    .icon-arrow-sm
      transform: translate3d(0,-15px,0)

// @keyframes close-btn
//   from
//     transform: translate3d(0,20px,0)
//     opacity: 0
//   to
//     transform: translate3d(0,0,0)
//     opacity: 1

.object__gallery
  position: relative
  z-index: 0
  margin: auto

.object__slider
  position: relative
  height: 100%
  height: 450px
  overflow: hidden
  &.slick-slider
    overflow: visible
  .slick-list,
  .slick-track
    height: 100%

.object__slide
  width: 100%
  height: 100%
  overflow: hidden
  background: #F0F0F0 no-repeat center center
  background-size: cover
  transition: transform 0.3s linear
  cursor: pointer
  > img
    display: block
    max-width: 100%
  &:hover
    transform: scale(1.1)
    transition-duration: 5s
    transition-timing-function: ease-out

.object__slider-prev,
.object__slider-next
  position: absolute
  z-index: 1
  top: 0
  bottom: 0
  width: 50%
  background: none
  color: #FFF
  +f(hb, 10, 0.2)
  text-transform: uppercase
  transition: opacity 0.3s linear
  opacity: 0
  white-space: nowrap
  > span
    display: none
    position: relative
    top: 110px
    transition: transform 0.4s ease
  .icon-arrow
    position: relative
    top: 1px
    transition: transform 0.6s ease
    fill: #FFF
  &:hover
    opacity: 1
    cursor: pointer

.object__slider-prev
  left: 0
  .icon-arrow
    transform: translate3d(0,0,0) rotate(180deg)
  > span
    right: 30px
  &:hover
    cursor: url('../img/prev.png'), pointer
    > span
      transform: translate3d(-50px,0,0)
    .icon-arrow
      transform: translate3d(-15px,0,0) rotate(180deg)

.object__slider-next
  right: 0
  > span
    left: 30px
  &:hover
    cursor: url('../img/next.png'), pointer
    > span
      transform: translate3d(60px,0,0)
    .icon-arrow
      transform: translate3d(15px,0,0)


.object_wide
  width: auto
  height: 680px
  .info
    bottom: -122px
  .object__gallery
    height: 100%
  .object__slider
    height: 100%
  .object__detail
    display: block
  .object__buttons
    display: block
  .btn_object
    cursor: default
    pointer-events: none
    .btn__hover
      transition: all 0.2s linear
      opacity: 0
      visibility: hidden
  +rh(800)
    height: 580px



.info
  width: 650px
  height: 180px
  box-sizing: border-box
  box-shadow: 0 0 0 10px #FFF

.info__inner
  position: relative
  z-index: 2
  +clr

.info__cell
  float: left
  box-sizing: border-box
  height: 90px
  padding: 15px 5px 5px 40px

.info__cell1
  width: 400px
  padding: 0

.info__cell2
  width: 200px

.info__cell3
  width: 250px


.info-item
  +f(hr, 24, 0.2)
  line-height: 1.25
  position: relative
  sup
    vertical-align: super
    font-size: 12px
  .sold-badge
    position: absolute
    top: 0
    left: -15px
  &.is-sold
    color: $gray

.info-item__label
  display: block
  +f(hb, 10, 0.15)
  color: $gray
  line-height: 2.2
  text-transform: uppercase


.btn_object
  width: 400px
  height: 90px
  +f(hb, 22, 0.25)
  line-height: 1.1
  text-transform: uppercase
  small
    +f(hr, 14)
    line-height: 1.57
    text-transform: none
    color: $gray
    letter-spacing: 0
  .btn__front,
  .btn__hover-in
    padding: 20px 5px 5px 40px

//---------------------------------------------------------------------------
//            ^^^^^  end catalog-content  ^^^^^
//---------------------------------------------------------------------------




//---------------------------------------------------------------------------
//            ##### begin invest #####
//---------------------------------------------------------------------------

.invest
  width: 100%
  max-width: 790px
  margin: auto
  position: relative
  .deco
    position: absolute
  .deco-1
    top: -110px
    left: -120px
  .deco-2
    top: 430px
    right: -130px
  html[lang="en"] &
    .deco-2
      top: 280px


.btn_tab.btn_tab-mod
  width: 290px
  height: 160px
  .btn__hover-in,
  .btn__front
    padding: 30px 0 0 40px

.tabs_mod
  margin-left: -40px
  margin-right: -40px
  .tabs__footer-cell
    padding-left: 40px
    color: $text-light
    font-size: 14px
  .input
    padding-left: 40px
  .btn_submit
    .btn__hover-in
      padding-left: 40px
    .btn__front
      padding-left: 40px



//---------------------------------------------------------------------------
//            ^^^^^  end invest  ^^^^^
//---------------------------------------------------------------------------



//---------------------------------------------------------------------------
//            ##### begin not-found #####
//---------------------------------------------------------------------------

.not-found
  height: 100%
  height: 100vh
  box-sizing: border-box
  +vertical
  white-space: nowrap
  overflow: auto

  .btn-back
    position: absolute
    z-index: 2
    bottom: 100%
    left: 0
    margin-bottom: 10px

  .deco
    position: absolute
    z-index: 2
    top: 100%
    right: 25px
    margin-top: 20px

.not-found__inner
  display: inline-block
  vertical-align: middle
  font-size: 14px
  width: 790px
  margin: 210px auto 200px
  border: 3px solid $text
  height: 260px
  position: relative
  white-space: normal



.not-found__triangle
  position: absolute
  z-index: 0
  top: 50%
  left: 50%
  margin: -311px 0 0 -317px

.not-found__img
  position: absolute
  z-index: 1
  top: 50%
  left: 50%
  margin: -145px 0 0 -255px

.not-found__title
  position: absolute
  z-index: 3
  top: 100px
  left: 255px
  width: 195px
  +f(hb, 12, 0.2)
  text-transform: uppercase
  line-height: 1.5
  > span
    font-size: 18px
    display: block
    padding-bottom: 11px
    margin-bottom: 14px
    border-bottom: 4px solid
    letter-spacing: 0.5em
  html[lang="en"] &
    text-align: left

//---------------------------------------------------------------------------
//            ^^^^^  end not-found  ^^^^^
//---------------------------------------------------------------------------





//---------------------------------------------------------------------------
//            ##### begin popup #####
//---------------------------------------------------------------------------
.popup
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: #FFF
  z-index: 97
  transform: translate3d(0,100%,0)
  transition: transform 0.8s ease
  &.is-opened
    transform: translate3d(0,0,0)
    +scroll

//---------------------------------------------------------------------------
//            ^^^^^  end popup  ^^^^^
//---------------------------------------------------------------------------




//styles for preloader
.preload
  opacity: 0
  #header
    transform: translate3d(0,-100%,0)
    opacity: 0
    transition: all 0.4s ease-out 1s
  .slide__img
    transform: translate3d(0,100%,0)
    opacity: 0
    transition: all 0.8s ease-out
  .slide__content
    transform: translate3d(0,25%,0)
    opacity: 0
    transition: all 0.8s ease-out
  .main-slider .slick-dots
    transform: translate3d(-100%,0,0)
    opacity: 0
    transition: all 0.3s ease-out 1.5s

.pace-running
  opacity: 1

.pace-done
  opacity: 1
  #header,
  .slide__img,
  .slide__content,
  .main-slider .slick-dots
    transform: none
    opacity: 1
