=size($width, $height)
  font-size: $height + px
  width: ($width / $height)+em

.icon
  display: inline-block
  height: 1em
  width: 1em
  fill: $text
  stroke: inherit
  +size(20,20)

.icon-star-centre
  +size(117,23)

.icon-vn-star
  +size(73,23)

.icon-arrow
  width: 70px
  height: 9px

.icon-arrow-sm
  width: 9px
  height: 40px

.icon-polygon
  +size(42,46)
  fill: none
  stroke: $text

.icon-target
  font-size: 45px

.icon-figure
  +size(55,40)
