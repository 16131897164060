body
  min-width: $content-width
  min-height: $content-height
  width: 100%
  height: 100%
  overflow: hidden
  position: relative
  +r(1199)
    overflow-x: auto
    overflow-y: hidden

.center-wrap
  max-width: 1368px
  margin: 0 auto
  position: relative
  z-index: 0
  overflow: hidden
  height: 100%

#outer
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1
  overflow: hidden
  // padding-bottom: 510px
  +scroll
  +wos

#inner
  position: relative
  z-index: 2
  // padding-bottom: 510px
  margin-bottom: 510px
  background: #FFF

.content-wrapper
  background: #FFF
  position: relative
  z-index: 3
  margin-bottom: 510px

.l-center
  width: 100%
  max-width: 1080px
  padding: 0 70px
  box-sizing: border-box
  margin: auto

#header
  position: absolute
  z-index: 99
  top: 0
  left: 0
  right: 0
  margin: auto

#nav
  position: absolute
  z-index: 100
  top: 0
  right: 0
  bottom: 0