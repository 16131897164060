/* done by Coderiver.com.ua */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

body, html { height: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }
input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) { * { -webkit-text-size-adjust: none; } }
table { border-spacing: 0; border-collapse: collapse; width: 100%; }

@font-face { font-family: "Bebas"; src: url("../fonts/bebas.eot"); src: url("../fonts/bebas.eot?#iefix") format("embedded-opentype"), url("../fonts/bebas.woff") format("woff"), url("../fonts/bebas.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "HelveticaNeueCyr-Bold"; src: url("../fonts/helveticaneuecyr-bold.eot"); src: url("../fonts/helveticaneuecyr-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-bold.woff") format("woff"), url("../fonts/helveticaneuecyr-bold.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "HelveticaNeueCyr-Roman"; src: url("../fonts/helveticaneuecyr-roman.eot"); src: url("../fonts/helveticaneuecyr-roman.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-roman.woff") format("woff"), url("../fonts/helveticaneuecyr-roman.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "HelveticaNeueCyr-Ultralight"; src: url("../fonts/helveticaneuecyr-ultralight.eot"); src: url("../fonts/helveticaneuecyr-ultralight.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-ultralight.woff") format("woff"), url("../fonts/helveticaneuecyr-ultralight.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "HelveticaNeueCyr-Light"; src: url("../fonts/helveticaneuecyr-light.eot"); src: url("../fonts/helveticaneuecyr-light.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-light.woff") format("woff"), url("../fonts/helveticaneuecyr-light.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "PFBeauSansPro-Regular"; src: url("../fonts/pfbeausanspro-reg.eot"); src: url("../fonts/pfbeausanspro-reg.eot?#iefix") format("embedded-opentype"), url("../fonts/pfbeausanspro-reg.woff") format("woff"), url("../fonts/pfbeausanspro-reg.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "PFBeauSansPro-Light"; src: url("../fonts/pfbeausanspro-light.eot"); src: url("../fonts/pfbeausanspro-light.eot?#iefix") format("embedded-opentype"), url("../fonts/pfbeausanspro-light.woff") format("woff"), url("../fonts/pfbeausanspro-light.ttf") format("truetype"); font-weight: normal; font-style: normal; }
body { font-size: 16px; line-height: 1.4; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; color: #333; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.pace { position: fixed; z-index: 9999; min-width: 1200px; min-height: 630px; top: 0; left: 0; right: 0; bottom: 0; background: #FFF; overflow-x: hidden; overflow-y: scroll; right: -17px; }
.scrollbar-width-0 .pace { right: 0; }
.scrollbar-width-15 .pace { right: -15px; }
.scrollbar-width-16 .pace { right: -16px; }
.scrollbar-width-17 .pace { right: -17px; }
.mac-os .pace { right: -15px; }
.pace.pace-inactive { display: none; }

.pace-progress { transform: none !important; width: 100%; height: 100%; }

.pace-progress-inner { position: absolute; top: 355px; left: 50%; width: 4px; position: relative; transition: all 1s linear; transform: translate(-50%, -50%); }
.pace-progress-inner:before { content: ""; display: block; top: 0; left: 0; width: 100%; padding-bottom: 100%; border-radius: 50%; background-color: #75BDC4; background-image: linear-gradient(to top, #CDF0E9 27%, #2B92A5 100%); }
.pace-progress[data-progress="0"] .pace-progress-inner { width: 5.1px; }
.pace-progress[data-progress="1"] .pace-progress-inner { width: 10.2px; }
.pace-progress[data-progress="2"] .pace-progress-inner { width: 15.3px; }
.pace-progress[data-progress="3"] .pace-progress-inner { width: 20.4px; }
.pace-progress[data-progress="4"] .pace-progress-inner { width: 25.5px; }
.pace-progress[data-progress="5"] .pace-progress-inner { width: 30.6px; }
.pace-progress[data-progress="6"] .pace-progress-inner { width: 35.7px; }
.pace-progress[data-progress="7"] .pace-progress-inner { width: 40.8px; }
.pace-progress[data-progress="8"] .pace-progress-inner { width: 45.9px; }
.pace-progress[data-progress="9"] .pace-progress-inner { width: 51px; }
.pace-progress[data-progress="10"] .pace-progress-inner { width: 56.1px; }
.pace-progress[data-progress="11"] .pace-progress-inner { width: 61.2px; }
.pace-progress[data-progress="12"] .pace-progress-inner { width: 66.3px; }
.pace-progress[data-progress="13"] .pace-progress-inner { width: 71.4px; }
.pace-progress[data-progress="14"] .pace-progress-inner { width: 76.5px; }
.pace-progress[data-progress="15"] .pace-progress-inner { width: 81.6px; }
.pace-progress[data-progress="16"] .pace-progress-inner { width: 86.7px; }
.pace-progress[data-progress="17"] .pace-progress-inner { width: 91.8px; }
.pace-progress[data-progress="18"] .pace-progress-inner { width: 96.9px; }
.pace-progress[data-progress="19"] .pace-progress-inner { width: 102px; }
.pace-progress[data-progress="20"] .pace-progress-inner { width: 107.1px; }
.pace-progress[data-progress="21"] .pace-progress-inner { width: 112.2px; }
.pace-progress[data-progress="22"] .pace-progress-inner { width: 117.3px; }
.pace-progress[data-progress="23"] .pace-progress-inner { width: 122.4px; }
.pace-progress[data-progress="24"] .pace-progress-inner { width: 127.5px; }
.pace-progress[data-progress="25"] .pace-progress-inner { width: 132.6px; }
.pace-progress[data-progress="26"] .pace-progress-inner { width: 137.7px; }
.pace-progress[data-progress="27"] .pace-progress-inner { width: 142.8px; }
.pace-progress[data-progress="28"] .pace-progress-inner { width: 147.9px; }
.pace-progress[data-progress="29"] .pace-progress-inner { width: 153px; }
.pace-progress[data-progress="30"] .pace-progress-inner { width: 158.1px; }
.pace-progress[data-progress="31"] .pace-progress-inner { width: 163.2px; }
.pace-progress[data-progress="32"] .pace-progress-inner { width: 168.3px; }
.pace-progress[data-progress="33"] .pace-progress-inner { width: 173.4px; }
.pace-progress[data-progress="34"] .pace-progress-inner { width: 178.5px; }
.pace-progress[data-progress="35"] .pace-progress-inner { width: 183.6px; }
.pace-progress[data-progress="36"] .pace-progress-inner { width: 188.7px; }
.pace-progress[data-progress="37"] .pace-progress-inner { width: 193.8px; }
.pace-progress[data-progress="38"] .pace-progress-inner { width: 198.9px; }
.pace-progress[data-progress="39"] .pace-progress-inner { width: 204px; }
.pace-progress[data-progress="40"] .pace-progress-inner { width: 209.1px; }
.pace-progress[data-progress="41"] .pace-progress-inner { width: 214.2px; }
.pace-progress[data-progress="42"] .pace-progress-inner { width: 219.3px; }
.pace-progress[data-progress="43"] .pace-progress-inner { width: 224.4px; }
.pace-progress[data-progress="44"] .pace-progress-inner { width: 229.5px; }
.pace-progress[data-progress="45"] .pace-progress-inner { width: 234.6px; }
.pace-progress[data-progress="46"] .pace-progress-inner { width: 239.7px; }
.pace-progress[data-progress="47"] .pace-progress-inner { width: 244.8px; }
.pace-progress[data-progress="48"] .pace-progress-inner { width: 249.9px; }
.pace-progress[data-progress="49"] .pace-progress-inner { width: 255px; }
.pace-progress[data-progress="50"] .pace-progress-inner { width: 260.1px; }
.pace-progress[data-progress="51"] .pace-progress-inner { width: 265.2px; }
.pace-progress[data-progress="52"] .pace-progress-inner { width: 270.3px; }
.pace-progress[data-progress="53"] .pace-progress-inner { width: 275.4px; }
.pace-progress[data-progress="54"] .pace-progress-inner { width: 280.5px; }
.pace-progress[data-progress="55"] .pace-progress-inner { width: 285.6px; }
.pace-progress[data-progress="56"] .pace-progress-inner { width: 290.7px; }
.pace-progress[data-progress="57"] .pace-progress-inner { width: 295.8px; }
.pace-progress[data-progress="58"] .pace-progress-inner { width: 300.9px; }
.pace-progress[data-progress="59"] .pace-progress-inner { width: 306px; }
.pace-progress[data-progress="60"] .pace-progress-inner { width: 311.1px; }
.pace-progress[data-progress="61"] .pace-progress-inner { width: 316.2px; }
.pace-progress[data-progress="62"] .pace-progress-inner { width: 321.3px; }
.pace-progress[data-progress="63"] .pace-progress-inner { width: 326.4px; }
.pace-progress[data-progress="64"] .pace-progress-inner { width: 331.5px; }
.pace-progress[data-progress="65"] .pace-progress-inner { width: 336.6px; }
.pace-progress[data-progress="66"] .pace-progress-inner { width: 341.7px; }
.pace-progress[data-progress="67"] .pace-progress-inner { width: 346.8px; }
.pace-progress[data-progress="68"] .pace-progress-inner { width: 351.9px; }
.pace-progress[data-progress="69"] .pace-progress-inner { width: 357px; }
.pace-progress[data-progress="70"] .pace-progress-inner { width: 362.1px; }
.pace-progress[data-progress="71"] .pace-progress-inner { width: 367.2px; }
.pace-progress[data-progress="72"] .pace-progress-inner { width: 372.3px; }
.pace-progress[data-progress="73"] .pace-progress-inner { width: 377.4px; }
.pace-progress[data-progress="74"] .pace-progress-inner { width: 382.5px; }
.pace-progress[data-progress="75"] .pace-progress-inner { width: 387.6px; }
.pace-progress[data-progress="76"] .pace-progress-inner { width: 392.7px; }
.pace-progress[data-progress="77"] .pace-progress-inner { width: 397.8px; }
.pace-progress[data-progress="78"] .pace-progress-inner { width: 402.9px; }
.pace-progress[data-progress="79"] .pace-progress-inner { width: 408px; }
.pace-progress[data-progress="80"] .pace-progress-inner { width: 413.1px; }
.pace-progress[data-progress="81"] .pace-progress-inner { width: 418.2px; }
.pace-progress[data-progress="82"] .pace-progress-inner { width: 423.3px; }
.pace-progress[data-progress="83"] .pace-progress-inner { width: 428.4px; }
.pace-progress[data-progress="84"] .pace-progress-inner { width: 433.5px; }
.pace-progress[data-progress="85"] .pace-progress-inner { width: 438.6px; }
.pace-progress[data-progress="86"] .pace-progress-inner { width: 443.7px; }
.pace-progress[data-progress="87"] .pace-progress-inner { width: 448.8px; }
.pace-progress[data-progress="88"] .pace-progress-inner { width: 453.9px; }
.pace-progress[data-progress="89"] .pace-progress-inner { width: 459px; }
.pace-progress[data-progress="90"] .pace-progress-inner { width: 464.1px; }
.pace-progress[data-progress="91"] .pace-progress-inner { width: 469.2px; }
.pace-progress[data-progress="92"] .pace-progress-inner { width: 474.3px; }
.pace-progress[data-progress="93"] .pace-progress-inner { width: 479.4px; }
.pace-progress[data-progress="94"] .pace-progress-inner { width: 484.5px; }
.pace-progress[data-progress="95"] .pace-progress-inner { width: 489.6px; }
.pace-progress[data-progress="96"] .pace-progress-inner { width: 494.7px; }
.pace-progress[data-progress="97"] .pace-progress-inner { width: 499.8px; }
.pace-progress[data-progress="98"] .pace-progress-inner { width: 504.9px; }
.pace-progress[data-progress="99"] .pace-progress-inner { width: 510px; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }
[dir="rtl"] .slick-slide { float: right; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

body { min-width: 1200px; min-height: 630px; width: 100%; height: 100%; overflow: hidden; position: relative; }
@media only screen and (max-width: 1199px) { body { overflow-x: auto; overflow-y: hidden; } }

.center-wrap { max-width: 1368px; margin: 0 auto; position: relative; z-index: 0; overflow: hidden; height: 100%; }

#outer { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; overflow: hidden; overflow-x: hidden; overflow-y: scroll; right: -17px; -webkit-overflow-scrolling: touch; }
.scrollbar-width-0 #outer { right: 0; }
.scrollbar-width-15 #outer { right: -15px; }
.scrollbar-width-16 #outer { right: -16px; }
.scrollbar-width-17 #outer { right: -17px; }
.mac-os #outer { right: -15px; }

#inner { position: relative; z-index: 2; margin-bottom: 510px; background: #FFF; }

.content-wrapper { background: #FFF; position: relative; z-index: 3; margin-bottom: 510px; }

.l-center { width: 100%; max-width: 1080px; padding: 0 70px; box-sizing: border-box; margin: auto; }

#header { position: absolute; z-index: 99; top: 0; left: 0; right: 0; margin: auto; }

#nav { position: absolute; z-index: 100; top: 0; right: 0; bottom: 0; }

.icon { display: inline-block; height: 1em; width: 1em; fill: #333; stroke: inherit; font-size: 20px; width: 1em; }

.icon-star-centre { font-size: 23px; width: 5.08696em; }

.icon-vn-star { font-size: 23px; width: 3.17391em; }

.icon-arrow { width: 70px; height: 9px; }

.icon-arrow-sm { width: 9px; height: 40px; }

.icon-polygon { font-size: 46px; width: 0.91304em; fill: none; stroke: #333; }

.icon-target { font-size: 45px; }

.icon-figure { font-size: 40px; width: 1.375em; }

@keyframes img-slide-out { to { transform: scale(0.6); opacity: 0; } }
@keyframes img-slide-in { 0%, 20% { transform: translate3d(0, 100%, 0); opacity: 0; }
  100% { transform: translate3d(0, 0, 0); opacity: 1; } }
@keyframes content-slide-out { to { transform: translate3d(0, -50px, 0) scale(0.95); opacity: 0; } }
@keyframes content-slide-in { from { transform: translate3d(0, 50px, 0); opacity: 0; }
  to { transform: translate3d(0, 0, 0); opacity: 1; } }
@keyframes slide-left { from { transform: translate3d(-50px, 0, 0); opacity: 0; }
  to { transform: translate3d(0, 0, 0); opacity: 1; } }
@keyframes detail { from { transform: translate3d(0, 50px, 0); opacity: 0; }
  to { transform: translate3d(0, 0, 0); opacity: 1; } }
.header { padding: 17px 50px 0 30px; font-size: 12px; line-height: 23px; *zoom: 1; }
.header:after { content: " "; display: table; clear: both; }

.header__left { float: left; color: #AAA; }
.header__left .logo { margin-right: 35px; }
.header__left > span { display: inline-block; vertical-align: middle; }

.header__right { float: right; }

.header__center { width: 300px; margin: auto; text-align: center; }
.header__center .btn { position: relative; visibility: hidden; pointer-events: none; transform: translate3d(0, -50px, 0); opacity: 0; top: -6px; vertical-align: top; height: 23px; color: #AAA; transition: all 0.8s ease; }
.header__center .btn.is-visible { visibility: visible; opacity: 1; transform: translate3d(0, 0, 0); pointer-events: auto; }
.header__center .btn__hover { height: 35px; }

.header__phone { display: inline-block; line-height: 1.2; color: inherit; text-decoration: none; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.25em; }
.header__phone em { color: #AAA; font-style: normal; }

.header__lang { display: inline-block; margin-left: 12px; text-transform: uppercase; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.25em; background: none; color: inherit; text-decoration: none; }

.footer { position: fixed; z-index: 0; bottom: 0; left: 0; right: 0; height: 510px; box-sizing: border-box; font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.05em; background: #FFF; color: #CCC; overflow: hidden; }

.footer__map { position: absolute; z-index: 0; bottom: 60px; left: 0; right: 0; height: 450px; background: #ccc; }

.footer__bottom { position: absolute; z-index: 1; bottom: 0; left: 0; right: 0; height: 60px; padding: 15px 50px 15px 30px; line-height: 30px; box-sizing: border-box; *zoom: 1; }
.footer__bottom:after { content: " "; display: table; clear: both; }

.footer__copy { float: left; text-transform: uppercase; }

.footer__link { float: right; }
.footer__link > a { font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.3em; color: #333; text-decoration: none; margin-left: 5px; transition: color 0.3s linear; }
.footer__link > a:hover { color: #666666; }

.logo { display: inline-block; vertical-align: middle; color: inherit; text-decoration: none; fill: #333; font: 0/0 a; text-shadow: none; color: transparent; }

#map { width: 100%; height: 100%; overflow: hidden; }

.nav { width: 33px; min-height: 630px; }

.nav__inner { position: relative; transform: rotate(90deg); transform-origin: bottom left; width: 100vh; min-width: 630px; height: 33px; margin-top: -33px; overflow: hidden; }
.nav__inner:before { content: ""; position: absolute; bottom: 0; left: 23px; width: 10px; height: 10px; background: #333; }

.nav__list { position: relative; display: flex; flex-direction: row; justify-content: space-between; flex-wrap: nowrap; align-items: flex-end; align-content: center; height: 33px; width: 100%; width: 100vh; min-width: 630px; padding: 6px 30px 0 50px; box-sizing: border-box; }
.nav__list > li { display: flex; width: auto; }
.nav__list:before { content: ""; position: absolute; top: 0; left: 0; height: 6px; width: 100%; background: #AAA; }
.scrollbar-width-0 .nav__list:before { height: 9px; }

.nav__back { width: 100%; }
.nav__back .nav__list { color: #AAA; }
.nav__back .nav__list .nav__link:hover { color: #333; }

.nav__front { position: absolute; top: 0; left: 0; bottom: 0; width: 100%; overflow: hidden; pointer-events: none; }
.nav__front .nav__list { color: #333; }
.nav__front .nav__list:before { background: #333; }
.nav__front .nav__link:hover { color: #333; }

.nav__link { display: inline-block; padding-top: 20px; color: inherit; text-decoration: none; background: none; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.25em; text-transform: uppercase; line-height: 1; transition: color 0.3s linear; }

.blue-circle { width: 510px; height: 510px; border-radius: 50%; background-color: #75BDC4; background-image: linear-gradient(to top, #CDF0E9 27%, #2B92A5 100%); }

.price { display: block; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 72px; letter-spacing: 0.05em; }

.price__small { display: inline-block; font-size: 24px; letter-spacing: 0; }
.price__small sup { vertical-align: super; font-size: 12px; }

.details-btn { display: inline-block; background: none; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.5em; text-transform: uppercase; text-decoration: none; color: inherit; line-height: 40px; }
.details-btn > span { display: inline-block; vertical-align: middle; }
.details-btn .icon-arrow { vertical-align: middle; transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.details-btn:hover .icon-arrow { transform: translateX(20px); }

.section { position: relative; }

.deco { position: relative; display: inline-block; font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.25em; }

.deco__inner { position: relative; }

.deco_polygon .deco__inner { width: 41px; height: 46px; line-height: 46px; font-size: 18px; letter-spacing: 0.05em; text-align: center; }
.deco_polygon .icon-polygon { position: absolute; top: 0; left: 0; }

.deco_circle .deco__inner { width: 33px; height: 33px; border: 1px solid; text-align: center; line-height: 34px; font-size: 18px; letter-spacing: 0; border-radius: 50%; }

.heading { display: block; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 16px; letter-spacing: 0.3em; text-transform: uppercase; color: #333; }

.heading__small-text { margin-bottom: 5px; display: block; font-size: 12px; letter-spacing: 0.15em; color: #AAA; }

.heading_center { text-align: center; }

.heading_large { font-size: 18px; }
.heading_large .heading__small-text { font-size: 12px; }

.heading_small { font-size: 12px; }

.heading_red { font-size: 10px; color: #f00; margin-bottom: 17px; }

.heading_border { position: relative; padding-bottom: 17px; }
.heading_border:after { content: ""; position: absolute; bottom: 0; left: 0; width: 100px; height: 3px; background: #333; }
.heading_border .heading__small-text { margin-bottom: 8px; color: inherit; }

.text { font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 16px; line-height: 1.375; }
.text p { margin: 12px 0; }
.text p:first-child { margin-top: 0; }
.text p:last-child { margin-top: 0; }

.amount { font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 18px; line-height: 1; }

.amount__sign { font-size: 30px; display: inline-block; vertical-align: middle; margin-right: 10px; }

.amount__value { font-size: 36px; display: inline-block; vertical-align: middle; font-weight: normal; }

.amount__text { position: relative; display: inline-block; vertical-align: bottom; bottom: 3px; font-size: 18px; margin-left: 10px; }

.amount_md .amount__value { font-size: 48px; }
.amount_md .amount__text { bottom: 5px; }

.amount_lrg .amount__sign { font-size: 48px; }
.amount_lrg .amount__value { font-size: 60px; }
.amount_lrg .amount__text { font-size: 24px; bottom: 6px; }

.btn { display: inline-block; position: relative; box-sizing: border-box; -webkit-appearance: none; text-align: left; text-decoration: none; color: #333; background: none; white-space: nowrap; }
.btn:hover .btn__hover, .btn.is-active .btn__hover { width: 100%; }

.btn__hover { position: absolute; z-index: 1; top: 0; left: 0; bottom: 0; width: 0; background: #333; color: #FFF; transition: width 0.3s linear; overflow: hidden; }

.btn__hover-in { box-sizing: border-box; height: 100%; position: relative; z-index: 1; }

.btn__front { position: relative; box-sizing: border-box; z-index: 0; }

.btn_link { width: 350px; height: 44px; border: 3px solid #333; color: #333; line-height: 38px; text-align: center; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.2em; text-transform: uppercase; }
.btn_link .btn__hover:before { content: attr(data-hover); display: block; width: 343px; height: 38px; color: #FFF; }

.btn_submit { height: 72px; line-height: 72px; width: 100%; white-space: nowrap; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.3em; color: #777; text-transform: uppercase; }
.btn_submit .icon-arrow { position: relative; top: 1px; margin-left: 10px; fill: currentColor; transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.btn_submit .btn__hover-in, .btn_submit .btn__front { padding: 0 0 0 70px; }
.btn_submit:hover .icon-arrow { transform: translate(20px, 0); }

.btn_transparent { font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.3em; color: #777; text-transform: uppercase; line-height: 35px; }
.btn_transparent .btn__hover { background: none; color: #333; }
.btn_transparent .btn__hover-in { color: #333; }
.btn_transparent .btn__hover-in:before { content: ""; position: absolute; bottom: 0; left: 0; width: 100%; height: 3px; background: #333; }

.form { position: relative; }

.form.is-success .form__message { opacity: 1; visibility: visible; }

.form__message { position: absolute; z-index: 5; top: 3px; left: 3px; right: 3px; bottom: 3px; opacity: 0; visibility: hidden; transition: 0.5s ease; background: #FFF; transition-property: opacity, visibility; overflow: hidden; text-align: center; font-size: 0; font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 16px; letter-spacing: 0.1em; }
.form__message:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }
.form__message strong { display: block; margin-bottom: 10px; font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 20px; letter-spacing: 0.3em; text-transform: uppercase; }
.contacts-table .form__message { top: 1px; left: 0; }

.form__message-inner { display: inline-block; vertical-align: middle; }

.form__contact { height: 184px; padding: 35px 0 0 40px; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 14px; color: #777; box-sizing: border-box; }
.form__contact > p { margin: 10px 0; color: #333; text-transform: uppercase; font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 14px; letter-spacing: 0.1em; }

.input { display: block; width: 100%; height: 52px; padding: 15px 15px 15px 70px; box-sizing: border-box; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 16px; letter-spacing: 0.03em; color: #333; transition: color 0.3s linear; border: none; box-shadow: none; -webkit-appearance: none; background: none; overflow: hidden; }
.input:-ms-input-placeholder { color: #777; line-height: normal; transition: all 0.5s ease; }
.input:-moz-placeholder { color: #777; line-height: normal; transition: all 0.5s ease; }
.input::-moz-placeholder { color: #777; line-height: normal; transition: all 0.5s ease; }
.input::-webkit-input-placeholder { color: #777; line-height: normal; transition: all 0.5s ease; }
.input::placeholder { color: #777; line-height: normal; transition: all 0.5s ease; }
.input:hover:-ms-input-placeholder { color: #333; }
.input:hover:-moz-placeholder { color: #333; }
.input:hover::-moz-placeholder { color: #333; }
.input:hover::-webkit-input-placeholder { color: #333; }
.input:hover::placeholder { color: #333; }
.input:focus:-ms-input-placeholder { color: #333; transform: translate(50px, 0); opacity: 0; }
.input:focus:-moz-placeholder { color: #333; transform: translate(50px, 0); opacity: 0; }
.input:focus::-moz-placeholder { color: #333; transform: translate(50px, 0); opacity: 0; }
.input:focus::-webkit-input-placeholder { color: #333; transform: translate(50px, 0); opacity: 0; }
.input:focus::placeholder { color: #333; transform: translate(50px, 0); opacity: 0; }

textarea.input { height: 214px; resize: none; margin: 0; }

.sold-badge { display: inline-block; white-space: nowrap; color: #ED1D24; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.5em; text-transform: uppercase; border: #ED1D24; line-height: 34px; border: 3px solid; padding: 0 20px; min-width: 200px; text-align: center; box-sizing: border-box; transform: rotate(-12deg); }

.btn-back { display: inline-block; background: none; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.5em; text-transform: uppercase; text-decoration: none; color: inherit; line-height: 40px; }
.btn-back > span { display: inline-block; vertical-align: middle; }
.btn-back .icon-arrow { vertical-align: middle; margin-right: 20px; transform: translate3d(0, 0, 0) rotate(180deg); transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.btn-back:hover .icon-arrow { transform: translate3d(-20px, 0, 0) rotate(180deg); }

#morph { position: absolute; z-index: 0; top: 15px; left: 50%; margin-left: -684px; width: 1368px; height: 680px; pointer-events: none; opacity: 0; visibility: hidden; }

#morph2 { position: absolute; z-index: 0; top: 50%; left: 50%; width: 1368px; height: 680px; margin: -340px 0 0 -684px; pointer-events: none; opacity: 0; }

.main-slider { position: relative; z-index: 1; height: 656px; max-width: 1368px; margin: auto; overflow: hidden; }
.main-slider .slick-dots { position: absolute; z-index: 3; top: 175px; left: 50%; width: 40px; margin-left: -20px; text-align: center; animation: slide-left 0.5s ease 1; }
.main-slider .slick-dots li { padding: 0; margin: 0; }
.main-slider .slick-dots button { display: block; margin-bottom: 5px; width: 40px; height: 40px; text-align: center; color: #333; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.25em; line-height: 2; background: none; opacity: 0.3; transition: opacity 0.3s linear; }
.main-slider .slick-dots button:hover { opacity: 1; }
.main-slider .slick-dots .slick-active button { opacity: 1; }

.main-slider__slides { transform-origin: top center; position: relative; z-index: 2; }

.main-slider__morph { position: absolute; top: 100px; left: 50%; width: 510px; height: 510px; margin-left: -255px; }

.main-slider__overlay { display: none; position: absolute; z-index: 5; top: 0; left: 0; width: 100%; height: 100%; background: #FFF; }

.main-slider__paginator { position: absolute; z-index: 3; top: 175px; left: 50%; width: 40px; margin-left: -20px; text-align: center; }
.main-slider__paginator button { display: block; margin-bottom: 5px; width: 40px; height: 40px; text-align: center; color: #333; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.25em; line-height: 2; background: none; opacity: 0.3; transition: opacity 0.3s linear; }
.main-slider__paginator button:hover, .main-slider__paginator button.is-active { opacity: 1; }

.slide { position: relative; z-index: 1; width: 100%; height: 656px; }
.slide.slick-active .slide__img { animation: img-slide-in 0.8s cubic-bezier(0, 0.99, 0, 0.99); }
.slide.slick-active .slide__content { animation: content-slide-in 0.8s ease; }

.slide__img { position: absolute; z-index: 0; bottom: 0; left: 0; max-width: 100%; transform-origin: bottom center; }
.slick-slide .slide__img { animation: img-slide-out 0.8s ease-out; }

.slide__content { position: absolute; z-index: 1; top: 180px; left: 54.09357%; width: 500px; font-size: 36px; line-height: 1.2; letter-spacing: 0.05em; }
.slick-slide .slide__content { animation: content-slide-out 0.8s ease; }

.catalog { height: 680px; }
.catalog #morph { z-index: 0; }
.catalog #morph.is-visible { z-index: 2; }
.catalog #morph.is-visible ~ .deco { opacity: 1; visibility: visible; transform: none; transition-delay: 1s; }

.catalog__modal { position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 680px; min-width: 1200px; transition: background 0.5s ease; }
.catalog__modal.is-opened { background: #FFF; }
.catalog__modal.is-opened .catalog__modal-container { overflow-x: hidden; overflow-y: scroll; right: -17px; -webkit-overflow-scrolling: touch; }
.scrollbar-width-0 .catalog__modal.is-opened .catalog__modal-container { right: 0; }
.scrollbar-width-15 .catalog__modal.is-opened .catalog__modal-container { right: -15px; }
.scrollbar-width-16 .catalog__modal.is-opened .catalog__modal-container { right: -16px; }
.scrollbar-width-17 .catalog__modal.is-opened .catalog__modal-container { right: -17px; }
.mac-os .catalog__modal.is-opened .catalog__modal-container { right: -15px; }

.catalog__modal-container { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }

.catalog__inner { position: relative; width: 100%; margin: auto; }

.catalog__toparea { position: relative; height: 680px; z-index: auto; }
.catalog__toparea .deco { position: absolute; z-index: 4; top: 145px; right: 125px; opacity: 0; visibility: hidden; transform: translateY(30px); transition: all 0.2s linear; }

.catalog__content { display: none; padding-bottom: 70px; position: relative; z-index: 1; }

.catalog-category { position: absolute; z-index: 3; top: 609px; left: 50%; height: 44px; width: 510px; margin-left: -255px; box-sizing: border-box; font-size: 0; }
.catalog-category:before { content: ""; position: absolute; z-index: 0; top: 0; left: 0; right: 0; width: 510px; height: 100%; margin: auto; background: #FFF; box-shadow: 0 0 0 3px #333, 0 0 0 13px #FFF; transition: opacity 0.2s ease, visibility 0.2s ease; visibility: visible; opacity: 1; }
.catalog-category.is-animate:before { opacity: 0; visibility: hidden; box-shadow: 0 0 0 13px #FFF; }
.catalog-category.is-animate .catalog-category__item { width: 33.3333%; }
.catalog-category.is-animate .catalog-category__item:before { opacity: 0; visibility: hidden; }
.catalog-category.is-animate .catalog-btn { color: #AAA; letter-spacing: 0.5em; width: auto; transition-duration: 0.3s; }
.catalog-category.is-animate .catalog-btn__hover { background: none; color: #333; box-shadow: none; }
.catalog-category.is-animate .catalog-btn__hover-in { width: auto; }
.catalog-category.is-animate .catalog-btn__hover-text:before { content: ""; position: absolute; top: 100%; left: 0; right: 0.33333em; height: 3px; background: #333; }

.catalog-category__item { position: relative; z-index: 1; display: inline-block; vertical-align: middle; width: 170px; height: 100%; text-align: center; overflow: hidden; pointer-events: none; }
.catalog-category__item:before { content: ""; position: absolute; top: 0; left: 0; bottom: 0; width: 1px; background: #333; transition: all 0.3s linear; opacity: 1; visibility: visible; }
.catalog-category__item:first-child:before { display: none; }
.catalog-category__item.clone { display: none; }
.catalog-category__item.clone.slick-slide { display: block; }
.catalog-category__item.slick-center .catalog-btn { font-size: 18px; }
.catalog-category__item.slick-center .catalog-btn__hover { width: 100%; }

.catalog-btn { display: inline-block; position: relative; z-index: 1; height: 44px; width: 170px; line-height: 44px; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.2em; color: #333; text-transform: uppercase; text-decoration: none; text-align: center; overflow: hidden; white-space: nowrap; pointer-events: auto; transform: translate3d(0, 0, 0); transition: all 0.15s linear; }
.catalog-btn:hover .catalog-btn__hover { width: 100%; }

.catalog-btn__hover { position: absolute; z-index: 1; top: 0; left: 0; width: 0; height: 100%; overflow: hidden; transition: width 0.2s linear; background: #333; color: #FFF; box-shadow: -2px 0 0 0 #333; }

.catalog-btn__hover-in { position: absolute; z-index: -1; top: 0; left: 0; bottom: 0; width: 170px; box-sizing: border-box; overflow: hidden; }

.catalog-btn__hover-text { display: inline-block; position: relative; line-height: 1.7; }

.facts { position: relative; padding: 360px 0 265px; box-sizing: border-box; }
.facts > .heading { text-align: center; }
.facts .deco { position: absolute; z-index: 2; }
.facts .deco-1 { top: 93px; right: 50%; margin-right: -330px; }
.facts .deco-2 { top: 1070px; left: 100px; }
.facts .deco-3 { top: 1850px; right: 240px; }
.facts .deco-4 { bottom: 340px; right: 165px; }

.facts__heading { text-align: center; position: relative; z-index: 3; }
.facts__heading .heading { display: inline-block; text-align: left; line-height: 1.875; }

.facts__text { max-width: 780px; margin: 200px auto 450px; *zoom: 1; }
.facts__text:after { content: " "; display: table; clear: both; }

.facts__column { position: relative; float: left; width: 50%; box-sizing: border-box; transform: translate3d(0, 50px, 0); opacity: 0; transition: transform 0.5s ease, opacity 0.5s ease; }
.facts__column:first-child { float: left; padding-right: 30px; }
.facts__column:last-child { float: right; padding-left: 30px; transition-delay: 0.1s; }
.facts__text.is-animate .facts__column { opacity: 1; transform: none; }

.facts-group { height: 270px; position: relative; margin: 165px 0 65px; }
.facts-group.is-animate .fact-2 { transform: none; }
.facts-group.is-animate .curve { stroke-dashoffset: 0; }

.facts-group__inner { position: relative; z-index: 1; max-width: 1000px; height: 100%; margin: auto; }

.box { position: relative; height: 520px; margin: 180px 0 145px; box-sizing: border-box; text-align: center; font-size: 0; white-space: nowrap; }
.box:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }

.box__bg { position: absolute; z-index: 0; top: 50%; left: 0; right: 0; height: 528px; margin-top: -264px; white-space: normal; pointer-events: none; transition: all 0.5s ease; opacity: 1; }
.box__bg .icon-man { opacity: 0; background: url("../img/svg/man.svg") no-repeat center center; display: inline-block; vertical-align: top; width: 22px; height: 44px; transform: translate3d(0, -30px, 0); }

.box__bg-row { height: 44px; background: url("../img/svg/man.svg") repeat-x center center; }

.box__inner { display: inline-block; vertical-align: middle; position: relative; z-index: 1; font-size: 16px; width: 720px; height: 190px; white-space: normal; }

.box__table { display: inline-block; width: 720px; height: 190px; position: relative; pointer-events: none; }
.box__table .table { position: absolute; top: 0; left: 0; }

.curve { position: absolute; z-index: 0; top: 50%; left: 50%; transform: translate(-50%, -50%); stroke-dasharray: 2621px; stroke-dashoffset: 1600px; }
.curve > svg { display: block; transition: stroke-dashoffset 3s ease; }

.skyline { position: relative; height: 272px; }
.skyline > svg { display: block; position: absolute; top: 0; left: 50%; transform: translate(-50%, 0); transition: stroke-dashoffset 2s ease; }
.skyline.is-animate { stroke-dashoffset: 0; }

.fact { position: absolute; width: 312px; padding: 0 0 0 40px; box-sizing: border-box; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 16px; }

.fact-1 { top: 90px; left: 0; }

.fact-2 { top: 0; right: 120px; transform: translate3d(-230px, 0, 0); transition: transform 2s ease; }

.fact__number { display: block; margin: 14px 0 14px -40px; padding: 10px 10px 10px 40px; border: 1px solid; box-sizing: border-box; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 30px; color: #333; background: #FFF; }

.timeline { position: absolute; z-index: 3; top: 450px; left: 50%; height: 1px; width: 1px; }
.timeline.is-animate .timeline__inner { transform: translate3d(-470px, 0, 0); width: 3100px; transition: width 4s ease, transform 1.5s ease; }
.timeline.is-animate .timeline__year { opacity: 1; transform: translate3d(0, 0, 0); }
.timeline.is-animate .timeline__year:nth-child(2) { transition-delay: 0.1s; }
.timeline.is-animate .timeline__year:nth-child(3) { transition-delay: 0.2s; }
.timeline.is-animate .timeline__year:nth-child(4) { transition-delay: 0.3s; }
.timeline.is-animate .timeline__year:nth-child(5) { transition-delay: 0.4s; }
.timeline.is-animate .timeline__year:nth-child(6) { transition-delay: 0.5s; }
.timeline.is-animate .timeline__year:nth-child(7) { transition-delay: 0.6s; }
.timeline.is-animate .timeline__year:nth-child(8) { transition-delay: 0.7s; }
.timeline.is-animate .timeline__year:nth-child(9) { transition-delay: 0.8s; }
.timeline.is-animate .timeline__year:nth-child(10) { transition-delay: 0.9s; }
.timeline.is-animate .timeline__year:nth-child(11) { transition-delay: 1s; }
.timeline.is-animate .timeline__year:nth-child(12) { transition-delay: 1.1s; }
.timeline.is-animate .timeline__year:nth-child(13) { transition-delay: 1.2s; }
.timeline.is-animate .timeline__year:nth-child(14) { transition-delay: 1.3s; }
.timeline.is-animate .timeline__year:nth-child(15) { transition-delay: 1.4s; }
.timeline.state-2 .timeline__inner { transform: translate3d(-1800px, 554px, 0); }
.timeline.state-2 .timeline__year:nth-child(12) { transform: translate3d(315px, 0, 0); transition-delay: 0s; transition-duration: 1.5s; }
.timeline.state-2 .timeline__year:nth-child(13) { transform: translate3d(738px, 0, 0); transition-delay: 0s; transition-duration: 1.5s; }

.timeline__inner { position: absolute; top: 0; left: 0; height: 1px; width: 1px; background: #333; transition: width 2s ease, transform 1.5s ease; }

.timeline__year { position: absolute; bottom: 0; width: 35px; height: 30px; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.15em; transform: translate3d(0, -100%, 0); opacity: 0; transition: all 0.3s ease; }
.timeline__year:before { content: ""; position: absolute; bottom: -4.5px; left: 0; width: 5px; height: 10px; background: #333; }
.timeline__year:first-child { transform: none; opacity: 1; }
.timeline__year:first-child:before { width: 10px; height: 10px; border-radius: 50%; }
.timeline__year:nth-child(2) { left: 48px; transition-delay: 1.4s; }
.timeline__year:nth-child(3) { left: 105px; transition-delay: 1.3s; }
.timeline__year:nth-child(4) { left: 168px; transition-delay: 1.2s; }
.timeline__year:nth-child(5) { left: 240px; transition-delay: 1.1s; }
.timeline__year:nth-child(6) { left: 320px; transition-delay: 1s; }
.timeline__year:nth-child(7) { left: 410px; transition-delay: 0.9s; }
.timeline__year:nth-child(8) { left: 505px; transition-delay: 0.8s; }
.timeline__year:nth-child(9) { left: 610px; transition-delay: 0.7s; }
.timeline__year:nth-child(10) { left: 720px; transition-delay: 0.6s; }
.timeline__year:nth-child(11) { left: 850px; transition-delay: 0.5s; }
.timeline__year:nth-child(12) { left: 985px; transition-delay: 0.4s; }
.timeline__year:nth-child(13) { left: 1130px; transition-delay: 0.3s; }
.timeline__year:nth-child(14) { left: 1300px; transition-delay: 0.2s; }
.timeline__year:nth-child(15) { left: 1500px; transition-delay: 0.1s; }

.table { position: relative; background: #FFF; }
.table > table { width: 100%; table-layout: fixed; position: relative; z-index: 1; text-align: left; }
.table td, .table th { box-sizing: border-box; }

.js-table { transition: opacity 0.3s linear 1s, visibility 0.3s linear 1s; opacity: 0; pointer-events: none; visibility: hidden; }
.js-table.is-animate, .object.is-fixed .js-table { transition-delay: 0s; opacity: 1; visibility: visible; pointer-events: auto; }
.js-table.is-animate .table__border, .object.is-fixed .js-table .table__border { stroke-dashoffset: 0; }
.js-table.is-animate .table__border .outer-border, .object.is-fixed .js-table .table__border .outer-border { transition-duration: 1s; transition-delay: 0.3s; }
.js-table.is-animate .table__border .inner-border, .object.is-fixed .js-table .table__border .inner-border { transition-duration: 1s; transition-delay: 0.6s; }

.table__border { position: absolute; z-index: 0; top: 0; left: 0; right: 0; bottom: 0; pointer-events: none; }
.table__border svg { display: block; transform: translate(0, 0); }
.table__border .outer-border { transition: stroke-dashoffset 1s ease 0.3s; }
.table__border .inner-border { transition: stroke-dashoffset 1s ease; }
.men-table .table__border { stroke-dasharray: 910px; stroke-dashoffset: 910px; }
.partners-table .table__border { stroke-dasharray: 910px; stroke-dashoffset: 910px; }
.tabs__head .table__border { stroke-dasharray: 1195px; stroke-dashoffset: 1195px; }
.tabs__content .table__border { stroke-dasharray: 1464px; stroke-dashoffset: 1464px; }
.contacts-table .table__border { stroke-dasharray: 1568px; stroke-dashoffset: 1568px; }
.info .table__border { stroke-dasharray: 825px; stroke-dashoffset: 825px; }
.table__border.is-animate { stroke-dashoffset: 0; }
.table__border.is-animate .outer-border { transition-duration: 1s; transition-delay: 0.3s; }
.table__border.is-animate .inner-border { transition-duration: 1s; transition-delay: 0.6s; }

.men-table { z-index: 1; width: 720px; }

.men-table__cell { text-align: left; padding: 0 10px 0 45px; vertical-align: middle; box-sizing: border-box; }
.men-table__cell:first-child { width: 196px; }
.men-table__cell:nth-child(2) { width: 235px; }
.men-table__cell:last-child { width: 290px; }

.men-table__row1 .men-table__cell { height: 90px; }

.men-table__row2 .men-table__cell { height: 100px; }

.partners-table { z-index: 0; width: 720px; margin: 0 auto 50px; }

.partners-table__row1 .partners-table__cell { height: 124px; }
.partners-table__row1 .partners-table__cell:first-child { vertical-align: top; padding: 40px 0 0 50px; }

.partners-table__row2 .partners-table__cell { height: 66px; }

.partners-table__cell { vertical-align: middle; padding-left: 70px; box-sizing: border-box; }
.partners-table__cell:first-child { width: 202px; }

.head { position: relative; margin-bottom: 90px; height: 423px; }
.head.last-schild { margin-bottom: 0; }
.head .deco { position: absolute; z-index: 3; pointer-events: none; }
.head .deco-1 { top: 230px; right: 100px; }
.head .deco-2 { top: -190px; left: 150px; }

.head__inner { position: absolute; bottom: 0; left: 0; right: 0; height: 100%; overflow: hidden; }

.head__bg { position: absolute; z-index: 0; bottom: 0; left: 0; right: 0; height: 423px; background-image: linear-gradient(to top, #CDF0E9 0%, #42BAAD 100%); }

.head__img { position: absolute; z-index: 1; bottom: 0; left: 120px; }
.head__img img { display: block; }

.head__text { position: absolute; z-index: 2; bottom: 146px; left: 70px; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 72px; letter-spacing: 0.4em; line-height: 1.75; text-transform: uppercase; pointer-events: none; }

.head_advantages .head__inner { height: 541px; }
.head_advantages .head__img { left: 50%; margin-left: -560px; }

.head_capabilities { margin-bottom: 0; }
.head_capabilities .deco-1 { top: 180px; right: 458px; }
.head_capabilities .head__text { bottom: 73px; }

.head_partners .deco-1 { top: -90px; right: 345px; }
.head_partners .head__bg { background-image: linear-gradient(to top, #EDE4F6 0%, #4297BA 100%); }
.head_partners .head__img { left: auto; right: 0; }

.head_contacts .head__inner { height: 500px; }
.head_contacts .deco-1 { top: 54px; right: 85px; }
.head_contacts .deco-2 { top: -120px; left: 75px; }
.head_contacts .head__bg { background-image: linear-gradient(to top, #FFDCC5 0%, #25A1B0 100%); }
.head_contacts .head__img { left: 50%; margin-left: -560px; }

.advantages__inner { max-width: 900px; margin: auto; padding-left: 150px; box-sizing: border-box; *zoom: 1; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 14px; color: #777; line-height: 1.57; }
.advantages__inner:after { content: " "; display: table; clear: both; }

.advantages__column { float: left; width: 33.3333%; box-sizing: border-box; padding-left: 50px; }
.advantages__column .heading { margin-bottom: 25px; }

.capabilities__container { position: relative; height: 715px; margin-top: -190px; width: 100%; }

.capabilities-modal { position: absolute; top: 0; left: 0; width: 100%; transition: background 0.5s ease; }
.capabilities-modal.is-opened { background: #FFF; }
.capabilities-modal.is-opened .capabilities-modal__inner { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow-x: hidden; overflow-y: scroll; right: -17px; -webkit-overflow-scrolling: touch; }
.scrollbar-width-0 .capabilities-modal.is-opened .capabilities-modal__inner { right: 0; }
.scrollbar-width-15 .capabilities-modal.is-opened .capabilities-modal__inner { right: -15px; }
.scrollbar-width-16 .capabilities-modal.is-opened .capabilities-modal__inner { right: -16px; }
.scrollbar-width-17 .capabilities-modal.is-opened .capabilities-modal__inner { right: -17px; }
.mac-os .capabilities-modal.is-opened .capabilities-modal__inner { right: -15px; }

.capabilities-modal__content { display: none; padding-bottom: 70px; }

.capabilities-modal__head { position: relative; max-width: 1368px; height: 680px; margin: auto; }

.category { position: absolute; z-index: 1; top: 50%; left: 50%; right: 0; width: 1500px; margin: auto; transform: translate(-50%, -50%); *zoom: 1; }
.category:after { content: " "; display: table; clear: both; }
.category.is-standby .slick-center .btn__bg { transition-property: all; }
.category.is-animate .slick-center .btn__bg { opacity: 0; transition: none; }
.category.is-active .btn_category { font-size: 10px; color: #AAA; transform: translate3d(0, 0, 0) !important; }
.category.is-active .btn_category:hover .btn__bg { opacity: 0; }
.category.is-active .btn__bg { transform: translate3d(-50px, 0, 0); opacity: 0; visibility: hidden; }
.category.is-active .slick-center .btn { font-size: 18px; color: #333; }
.category.is-active .slick-center .btn__hover { width: 100%; }
.category.is-active .slick-center .btn__bg { transform: translate3d(0, 0, 0); transition: none; }
.category.is-active .slick-center:not(.slick-cloned) ~ .slick-slide .btn__bg { transform: translate3d(50px, 0, 0); }
.category.is-active .slick-slide { opacity: 1; }

.category__item { float: left; width: 33.333%; line-height: 400px; text-align: center; pointer-events: none; transition: opacity 0.3s ease; }
@media only screen and (min-width: 1400px) { .category__item.slick-slide { opacity: 0; }
  .category__item.slick-active { opacity: 1; } }

.btn_category { line-height: 2; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 18px; letter-spacing: 0.5em; text-transform: uppercase; pointer-events: auto; transition: all 0.5s ease; }
.btn_category .btn__hover { background: none; color: #333; }
.btn_category .btn__hover-in:before { content: ""; position: absolute; bottom: 0; left: 0; right: 0.33333em; height: 3px; background: #333; }
.btn_category .btn__bg { position: absolute; z-index: -1; top: 50%; left: 50%; width: 320px; height: 320px; margin: -159px 0 0 -160px; background: no-repeat center center; opacity: 0.2; transition: all 0.5s ease-out; }
.slick-center .btn_category .btn__bg { transition-property: transform; }
.btn_category .btn__bg1 { background-image: url("../img/category1.png"); }
.btn_category .btn__bg2 { background-image: url("../img/category2.png"); }
.btn_category .btn__bg3 { background-image: url("../img/category3.png"); }
.btn_category:hover .btn__bg, .btn_category.is-hover .btn__bg { opacity: 1; }
.slick-slide .btn_category { transform: translate3d(100px, 0, 0); }
.slick-center:not(.slick-cloned) ~ .slick-slide .btn_category { transform: translate3d(-100px, 0, 0); }
.slick-center.slick-slide .btn_category { transform: translate3d(0, 0, 0); }

.partners { padding-bottom: 235px; }

.partners__inner { position: relative; max-width: 1080px; padding: 0 70px; margin: auto; box-sizing: border-box; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 14px; line-height: 1.44; color: #777; }
.partners__inner .deco { position: absolute; top: 40px; right: 140px; }

.text-block { margin-bottom: 70px; font-size: 16px; color: #777; }
.text-block .heading { margin-bottom: 25px; }

.tabs { margin: 35px -70px 0; }

.tabs__head { position: relative; z-index: 1; pointer-events: none; opacity: 0; visibility: hidden; transition: opacity 0.3s linear 1s, visibility 0.3s linear 1s; *zoom: 1; }
.tabs__head:after { content: " "; display: table; clear: both; }
.tabs.is-animate .tabs__head { pointer-events: auto; visibility: visible; opacity: 1; transition-delay: 0s; }
.tabs.is-animate .tabs__head .table__border { stroke-dashoffset: 0; }
.tabs.is-animate .tabs__head .table__border .outer-border { transition-duration: 1s; transition-delay: 0.3s; }
.tabs.is-animate .tabs__head .table__border .inner-border { transition-duration: 1s; transition-delay: 0.6s; }

.tabs__content { display: none; position: relative; z-index: 0; top: -3px; }

.tabs__footer { margin-top: 35px; transform: translate3d(0, 0, 0); *zoom: 1; }
.tabs__footer:after { content: " "; display: table; clear: both; }

.tabs__footer-cell { float: left; width: 33.333%; box-sizing: border-box; padding: 0 0 0 70px; transform: translate3d(0, 50px, 0); opacity: 0; transition: transform 0.5s ease, opacity 0.5s ease; }
.tabs.is-animate .tabs__footer-cell { opacity: 1; transform: none; }
.tabs__footer-cell:nth-child(2) { transition-delay: 0.1s; }
.tabs__footer-cell:nth-child(3) { transition-delay: 0.2s; }

.btn_tab { float: left; width: 360px; height: 120px; }
.btn_tab .btn__hover-in { width: 360px; }
.btn_tab .heading { color: inherit; }
.btn_tab .btn__front, .btn_tab .btn__hover-in { padding: 22px 0 0 70px; }

.contacts { padding-bottom: 80px; }

.contacts__inner { width: 1080px; margin: auto; }

.contacts-table .form { margin-left: -70px; }

.contacts-table__row1 .contacts-table__cell { height: 104px; }

.contacts-table__row2 .contacts-table__cell { height: 286px; }

.contacts-table__cell { box-sizing: border-box; padding-left: 70px; vertical-align: middle; }
.contacts-table__cell:first-child { width: 454px; }

.contact-item { font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 18px; letter-spacing: 0.1em; line-height: 1.667; text-transform: uppercase; }
.contact-item > a { text-decoration: none; color: inherit; }

.contact-item__label { display: block; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.2em; line-height: 2.2; color: #777; text-transform: uppercase; }

.contact-item__phone { font-size: 24px; }

.filter { width: 790px; box-sizing: border-box; margin: 0 auto 70px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; position: relative; }
.filter > .deco { position: absolute; top: -100px; left: -140px; right: auto; bottom: auto; }
.filter + .object-wrapper { margin-top: 70px; }

.filter__item { display: inline-block; vertical-align: top; position: relative; cursor: pointer; }
.filter__item > input { position: absolute; opacity: 0; top: 0; left: 0; }
.filter__item input:checked ~ .btn .btn__hover { width: 100%; }
.filter__item .btn { user-select: none; }

.object-wrapper { width: 790px; height: 450px; position: relative; margin: 130px auto; }
.object-wrapper:first-child { margin-top: 0; }
.object-wrapper:last-child { margin-bottom: 0; }
.object-wrapper.is-animate .object-label { opacity: 0; visibility: hidden; }
.object-wrapper.is-slider { width: 100%; height: auto; transition: height 0.5s linear; }
.object-wrapper.is-slider .object-label { opacity: 0; visibility: hidden; }
.object-wrapper.is-slider .object { height: auto; }
.object-wrapper.is-slider .object__inner { position: relative; }
.object-wrapper.is-slider .object__detail { padding-bottom: 0; animation: none; }

.object-label { display: block; position: absolute; z-index: 1; top: -55px; left: -65px; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 24px; letter-spacing: 1em; line-height: 1.333; text-transform: uppercase; transition: all 0.2s linear; opacity: 1; visibility: visible; }
html[lang="en"] .object-label { top: -35px; }

.object { position: relative; width: 790px; height: 450px; background: #FFF; }
.object .info { position: absolute; z-index: 5; left: 50%; bottom: -22px; margin-left: -225px; }
.object .info .table__border { stroke-dashoffset: 0; }
.object .slick-dots { position: absolute; top: 100%; margin-top: 10px; left: 20px; right: 50%; padding-right: 235px; font-size: 0; animation: slide-left 0.5s ease 1; }
.object .slick-dots li { display: inline-block; vertical-align: middle; }
.object .slick-dots li button { width: 40px; height: 40px; background: none; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.2em; text-transform: uppercase; color: #AAA; transition: color 0.3s linear; }
.object .slick-dots li.slick-active button, .object .slick-dots li:hover button { color: #333; }
.object.is-opened .object__buttons, .object.is-slider .object__buttons { visibility: visible; opacity: 1; }
.object.is-opened .btn_object, .object.is-slider .btn_object { cursor: default; pointer-events: none; }
.object.is-opened .btn_object .btn__hover, .object.is-slider .btn_object .btn__hover { transition: all 0.2s linear; opacity: 0; visibility: hidden; }
.object.is-opened .object__slide, .object.is-slider .object__slide { transition: none; transform: none; }
.object.is-opened .object__inner { overflow-x: hidden; overflow-y: scroll; right: -17px; }
.scrollbar-width-0 .object.is-opened .object__inner { right: 0; }
.scrollbar-width-15 .object.is-opened .object__inner { right: -15px; }
.scrollbar-width-16 .object.is-opened .object__inner { right: -16px; }
.scrollbar-width-17 .object.is-opened .object__inner { right: -17px; }
.mac-os .object.is-opened .object__inner { right: -15px; }
.object.is-opened .object__detail { display: block; }

.object__inner { position: absolute; z-index: 0; top: 0; left: 0; right: 0; bottom: 0; }

.object__pic { position: relative; z-index: 0; width: 100%; height: 100%; background: no-repeat center center; background-size: cover; }

.object__buttons { visibility: hidden; opacity: 0; position: absolute; z-index: 1; top: 0; left: 0; right: 0; bottom: 0; transition: 0.3s ease; transition-property: visibility, opacity; }
.popup.is-opened .object__buttons { opacity: 1; visibility: visible; }

.object__detail { display: none; width: 790px; margin: 160px auto 0; box-sizing: border-box; padding: 0 0 70px 210px; font-family: "PFBeauSansPro-Regular", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 18px; line-height: 1.555; animation: detail 0.5s ease; }
.object__detail .btn_link { margin-top: 40px; }

.object__close { position: absolute; z-index: 3; top: 0; left: 50%; width: 500px; height: 170px; box-sizing: border-box; background: none; margin-left: -250px; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.2em; text-transform: uppercase; color: #FFF; text-shadow: 0 1px 2px rgba(0, 0, 0, 0.35); }
.object__close > span { display: inline-block; }
.object__close .icon-arrow-sm { position: absolute; fill: #FFF; top: 50%; left: 50%; margin: -50px 0 0 -4px; transition: transform 0.6s ease; }
.object__close:hover .icon-arrow-sm { transform: translate3d(0, -15px, 0); }

.object__gallery { position: relative; z-index: 0; margin: auto; }

.object__slider { position: relative; height: 100%; height: 450px; overflow: hidden; }
.object__slider.slick-slider { overflow: visible; }
.object__slider .slick-list, .object__slider .slick-track { height: 100%; }

.object__slide { width: 100%; height: 100%; overflow: hidden; background: #F0F0F0 no-repeat center center; background-size: cover; transition: transform 0.3s linear; cursor: pointer; }
.object__slide > img { display: block; max-width: 100%; }
.object__slide:hover { transform: scale(1.1); transition-duration: 5s; transition-timing-function: ease-out; }

.object__slider-prev, .object__slider-next { position: absolute; z-index: 1; top: 0; bottom: 0; width: 50%; background: none; color: #FFF; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.2em; text-transform: uppercase; transition: opacity 0.3s linear; opacity: 0; white-space: nowrap; }
.object__slider-prev > span, .object__slider-next > span { display: none; position: relative; top: 110px; transition: transform 0.4s ease; }
.object__slider-prev .icon-arrow, .object__slider-next .icon-arrow { position: relative; top: 1px; transition: transform 0.6s ease; fill: #FFF; }
.object__slider-prev:hover, .object__slider-next:hover { opacity: 1; cursor: pointer; }

.object__slider-prev { left: 0; }
.object__slider-prev .icon-arrow { transform: translate3d(0, 0, 0) rotate(180deg); }
.object__slider-prev > span { right: 30px; }
.object__slider-prev:hover { cursor: url("../img/prev.png"), pointer; }
.object__slider-prev:hover > span { transform: translate3d(-50px, 0, 0); }
.object__slider-prev:hover .icon-arrow { transform: translate3d(-15px, 0, 0) rotate(180deg); }

.object__slider-next { right: 0; }
.object__slider-next > span { left: 30px; }
.object__slider-next:hover { cursor: url("../img/next.png"), pointer; }
.object__slider-next:hover > span { transform: translate3d(60px, 0, 0); }
.object__slider-next:hover .icon-arrow { transform: translate3d(15px, 0, 0); }

.object_wide { width: auto; height: 680px; }
.object_wide .info { bottom: -122px; }
.object_wide .object__gallery { height: 100%; }
.object_wide .object__slider { height: 100%; }
.object_wide .object__detail { display: block; }
.object_wide .object__buttons { display: block; }
.object_wide .btn_object { cursor: default; pointer-events: none; }
.object_wide .btn_object .btn__hover { transition: all 0.2s linear; opacity: 0; visibility: hidden; }
@media only screen and (max-height: 800px) { .object_wide { height: 580px; } }

.info { width: 650px; height: 180px; box-sizing: border-box; box-shadow: 0 0 0 10px #FFF; }

.info__inner { position: relative; z-index: 2; *zoom: 1; }
.info__inner:after { content: " "; display: table; clear: both; }

.info__cell { float: left; box-sizing: border-box; height: 90px; padding: 15px 5px 5px 40px; }

.info__cell1 { width: 400px; padding: 0; }

.info__cell2 { width: 200px; }

.info__cell3 { width: 250px; }

.info-item { font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 24px; letter-spacing: 0.2em; line-height: 1.25; position: relative; }
.info-item sup { vertical-align: super; font-size: 12px; }
.info-item .sold-badge { position: absolute; top: 0; left: -15px; }
.info-item.is-sold { color: #AAA; }

.info-item__label { display: block; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 10px; letter-spacing: 0.15em; color: #AAA; line-height: 2.2; text-transform: uppercase; }

.btn_object { width: 400px; height: 90px; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 22px; letter-spacing: 0.25em; line-height: 1.1; text-transform: uppercase; }
.btn_object small { font-family: "HelveticaNeueCyr-Roman", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 14px; line-height: 1.57; text-transform: none; color: #AAA; letter-spacing: 0; }
.btn_object .btn__front, .btn_object .btn__hover-in { padding: 20px 5px 5px 40px; }

.invest { width: 100%; max-width: 790px; margin: auto; position: relative; }
.invest .deco { position: absolute; }
.invest .deco-1 { top: -110px; left: -120px; }
.invest .deco-2 { top: 430px; right: -130px; }
html[lang="en"] .invest .deco-2 { top: 280px; }

.btn_tab.btn_tab-mod { width: 290px; height: 160px; }
.btn_tab.btn_tab-mod .btn__hover-in, .btn_tab.btn_tab-mod .btn__front { padding: 30px 0 0 40px; }

.tabs_mod { margin-left: -40px; margin-right: -40px; }
.tabs_mod .tabs__footer-cell { padding-left: 40px; color: #777; font-size: 14px; }
.tabs_mod .input { padding-left: 40px; }
.tabs_mod .btn_submit .btn__hover-in { padding-left: 40px; }
.tabs_mod .btn_submit .btn__front { padding-left: 40px; }

.not-found { height: 100%; height: 100vh; box-sizing: border-box; text-align: center; font-size: 0; white-space: nowrap; overflow: auto; }
.not-found:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }
.not-found .btn-back { position: absolute; z-index: 2; bottom: 100%; left: 0; margin-bottom: 10px; }
.not-found .deco { position: absolute; z-index: 2; top: 100%; right: 25px; margin-top: 20px; }

.not-found__inner { display: inline-block; vertical-align: middle; font-size: 14px; width: 790px; margin: 210px auto 200px; border: 3px solid #333; height: 260px; position: relative; white-space: normal; }

.not-found__triangle { position: absolute; z-index: 0; top: 50%; left: 50%; margin: -311px 0 0 -317px; }

.not-found__img { position: absolute; z-index: 1; top: 50%; left: 50%; margin: -145px 0 0 -255px; }

.not-found__title { position: absolute; z-index: 3; top: 100px; left: 255px; width: 195px; font-family: "HelveticaNeueCyr-Bold", Helvetica, Arial, sans-serif; font-weight: normal; font-size: 12px; letter-spacing: 0.2em; text-transform: uppercase; line-height: 1.5; }
.not-found__title > span { font-size: 18px; display: block; padding-bottom: 11px; margin-bottom: 14px; border-bottom: 4px solid; letter-spacing: 0.5em; }
html[lang="en"] .not-found__title { text-align: left; }

.popup { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #FFF; z-index: 97; transform: translate3d(0, 100%, 0); transition: transform 0.8s ease; }
.popup.is-opened { transform: translate3d(0, 0, 0); overflow-x: hidden; overflow-y: scroll; right: -17px; }
.scrollbar-width-0 .popup.is-opened { right: 0; }
.scrollbar-width-15 .popup.is-opened { right: -15px; }
.scrollbar-width-16 .popup.is-opened { right: -16px; }
.scrollbar-width-17 .popup.is-opened { right: -17px; }
.mac-os .popup.is-opened { right: -15px; }

.preload { opacity: 0; }
.preload #header { transform: translate3d(0, -100%, 0); opacity: 0; transition: all 0.4s ease-out 1s; }
.preload .slide__img { transform: translate3d(0, 100%, 0); opacity: 0; transition: all 0.8s ease-out; }
.preload .slide__content { transform: translate3d(0, 25%, 0); opacity: 0; transition: all 0.8s ease-out; }
.preload .main-slider .slick-dots { transform: translate3d(-100%, 0, 0); opacity: 0; transition: all 0.3s ease-out 1.5s; }

.pace-running { opacity: 1; }

.pace-done { opacity: 1; }
.pace-done #header, .pace-done .slide__img, .pace-done .slide__content, .pace-done .main-slider .slick-dots { transform: none; opacity: 1; }


