.pace
  position: fixed
  z-index: 9999
  min-width: $content-width
  min-height: $content-height
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: #FFF
  +scroll
  &.pace-inactive
    display: none

.pace-progress
  transform: none !important
  width: 100%
  height: 100%

.pace-progress-inner
  position: absolute
  top: 355px
  left: 50%
  width: 4px
  position: relative
  transition: all 1s linear
  transform: translate(-50%, -50%)
  &:before
    content: ""
    display: block
    top: 0
    left: 0
    width: 100%
    padding-bottom: 100%
    border-radius: 50%
    background-color: #75BDC4
    background-image: linear-gradient(to top, #CDF0E9 27%, #2B92A5 100%)
  @for $i from 1 through 100
    .pace-progress[data-progress="#{$i - 1}"] &
      width: 510 * ($i / 100) + px

// .pace-activity