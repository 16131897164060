@keyframes img-slide-out
  to
    transform: scale(0.6)
    opacity: 0

@keyframes img-slide-in
  0%, 20%
    transform: translate3d(0, 100%, 0)
    opacity: 0
  // 70%
  //   transform: translate3d(0, 2%, 0)
  //   opacity: 1
  100%
    transform: translate3d(0, 0, 0)
    opacity: 1


@keyframes content-slide-out
  to
    transform: translate3d(0, -50px, 0) scale(0.95)
    opacity: 0

@keyframes content-slide-in
  from
    transform: translate3d(0, 50px, 0)
    opacity: 0
  to
    transform: translate3d(0, 0, 0)
    opacity: 1


@keyframes slide-left
  from
    transform: translate3d(-50px, 0, 0)
    opacity: 0
  to
    transform: translate3d(0, 0, 0)
    opacity: 1

@keyframes detail
  from
    transform: translate3d(0, 50px, 0)
    opacity: 0
  to
    transform: translate3d(0, 0, 0)
    opacity: 1